import request from "../request";
import axios from "axios";
import { authAPI } from "./urls";
import { setTokens } from "../../lib/CognitoUtils";

export const getAccessToken = (code) => {
  console.log('window.location.origin > ', window.location.origin);
  const accessTokenURL = window.location.origin.includes('localhost') ? authAPI.getAccessTokenWithLocal(code, window.location.origin) : authAPI.getAccessToken(code);
  return axios
    .get(accessTokenURL)
    .then((response) => { 
      return response})
    .catch((error) => {
      console.log('getAccessToken error: ', error)
      return Promise.reject(error.response);
    });
};

export const getNewToken = (username, refreshToken) => {
  return new Promise((resolve, reject) => {
    request({
      url: authAPI.refreshAccessToken(username, refreshToken),
      method: "GET",
    })
      .then((response) => {
        const { access_token, id_token, user } = response;
        setTokens(access_token, id_token, user);
        resolve(access_token);
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  });
};

const authService = {
  getAccessToken,
  getNewToken,
};

export default authService;
