const { default: ActionTypes } = require("../ActionTypes");

const { COMMON_ACTIONS } = ActionTypes;

const INITIAL_STATE = {
    currentPage: 'Dashboard'
};

const CommonReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case COMMON_ACTIONS.SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: payload
            }
        default:
            return state;
    }
}

export default CommonReducer;