import ActionTypes from "../ActionTypes";
const { LIBOR_RATE } = ActionTypes;

export const setPaginatedLiborRates = (paginatedLiborRates) => ({
  type: LIBOR_RATE.SET_LIBOR_RATES_LIST,
  payload: paginatedLiborRates,
});

export const setIsLiborRateExpired = (isLiborRateExpired) => ({
  type: LIBOR_RATE.SET_IS_LIBOR_EXPIRED,
  payload: isLiborRateExpired,
});
