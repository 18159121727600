import { ActionTypes } from "../ActionTypes";

const { USER_ACTIONS } = ActionTypes;

const INITIAL_STATE = {
  currentUser: null,
  paginatedUsers: { count: 0, data: [] },
  rolesList: [],
  groupsList: [],
  user: null,
};

const UserReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_ACTIONS.SET_CURRENT_USER_SESSION:
      return {
        ...state,
        currentUser: payload,
      };
    case USER_ACTIONS.SET_USERS_LISTS:
      return {
        ...state,
        paginatedUsers: payload,
      };
    case USER_ACTIONS.SET_ROLES_LISTS:
      return {
        ...state,
        rolesList: payload,
      };
    case USER_ACTIONS.SET_GROUPS_LISTS:
      return {
        ...state,
        groupsList: payload,
      };
    case USER_ACTIONS.SET_USER:
      return {
        ...state,
        user: payload,
      };
    default:
      return state;
  }
};

export default UserReducer;
