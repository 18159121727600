import AppConfig from "../../config/AppConfig";
const { BASE_URL } = AppConfig;

export const authAPI = {
  getAccessToken: (code) => `${BASE_URL}auth/${code}`,
  getAccessTokenWithLocal: (code, originVal) => `${BASE_URL}auth/${code}?redirectUrl=${originVal}`,
  refreshAccessToken: (username, refreshToken) =>
    `${BASE_URL}auth/refreshToken/${username}?refreshToken=${refreshToken}`,
};

export const artworkAPI = {
  getArtWorkCargories: () => 
    `${BASE_URL}artworks/template/categories`,

  getArtWorkTags: () => 
    `${BASE_URL}artworks/template/tags`,
  
  getArtWorkLocations: () =>
    `${BASE_URL}artworks/template/locations`,
  
  getArtWorkContacts: () =>
    `${BASE_URL}artworks/template/contacts`,
  
  getArtWorkGroups: () =>
    `${BASE_URL}artworks/template/groups`,
  
  getArtWorkOwners: () => 
    `${BASE_URL}artworks/template/owners`,

  getArtWorkArtists: () => 
  `${BASE_URL}artworks/template/artists`,

  getArtWorkStatuses: () => 
    `${BASE_URL}artworks/template/statuses`,

  getArtWorkMediums: ()=> 
    `${BASE_URL}artworks/template/media`,
  
  getArtWorkCurrencies: () => 
    `${BASE_URL}artworks/template/currencies`,

  getArtWorkTransactionTypes: () =>
    `${BASE_URL}artworks/template/transactionTypes`,

  getArtWorkCollectionTags: () =>
   `${BASE_URL}artworks/template/collectionTags`,
  
  populateAddArtworkDropdown: (isArtist, forCollection) =>
    `${BASE_URL}artworks/template?isArtist=${isArtist}&artistForCollection=${forCollection}`,
  addArtwork: `${BASE_URL}artworks`,
  validateCardNumber: (number, artworkId) =>
    `${BASE_URL}artworks/cardNumber/${number}?artworkId=${artworkId ? artworkId : ""
    }`,
  viewArtworks: (page, rowPerPage) =>
    `${BASE_URL}artworks?page=${page}&rowPerPage=${rowPerPage}`,
  viewArtwork: (id, onlyAttachmentsList = false) =>
    `${BASE_URL}artworks/${id}?onlyAttachmentsList=${onlyAttachmentsList}`,
  viewArtworkAllInCost: (id) => `${BASE_URL}artworks/${id}/allincost`,
  editArtwork: (id) => `${BASE_URL}artworks/${id}`,
  uploadFiles: (artwork_id) => `${BASE_URL}artworks/upload/${artwork_id}`,
  getAttachmentByIdAndEntity: (id, entityName, thumbnail) =>
    `${BASE_URL}files/${id}?entityName=${entityName}&thumbnail=${thumbnail}&mainImage=${true}`,
  updateArtworkStatus: (id) => `${BASE_URL}artworks/${id}`,
  updateArtworkInsurancePrimary: (artworkId, insuraneId) =>
    `${BASE_URL}artworks/${artworkId}/insurance/${insuraneId}/setprimary`,
  viewMarketplaceArtworks: (page, rowPerPage) =>
    `${BASE_URL}artworks/marketplace/?page=${page}&rowPerPage=${rowPerPage}`,
  viewMarketplaceArtwork: (id) => `${BASE_URL}artworks/marketplace/${id}`,
  addToInCollection: (id) => `${BASE_URL}artworks/marketplace/${id}`,
  getLastUpdateStatusDate: (artwork_id) =>
    `${BASE_URL}artworks/${artwork_id}/status-date`,
  getStatusChangeDates: (artwork_id) =>
    `${BASE_URL}artworks/${artwork_id}/status-date?allDates=true`,
};

export const artworkTransactionAPI = {
  populateAddArtworkTransactionDropdown: (artworkId) =>
    `${BASE_URL}artworks/${artworkId}/transactions/template`,
  getTransactionDetails: (artworkId, transactionId) =>
    `${BASE_URL}artworks/${artworkId}/transactions/${transactionId}`,
  addArtworkTransaction: (artworkId) =>
    `${BASE_URL}artworks/${artworkId}/transactions`,
  editArtworkTransaction: (artworkId, transactionId) =>
    `${BASE_URL}artworks/${artworkId}/transactions/${transactionId}`,
  editAndDeleteArtworkInstallment: (artworkId, transactionId, instalmentId) =>
    `${BASE_URL}artworks/${artworkId}/transactions/${transactionId}/instalments/${instalmentId}`,
  addArtworkTransactionInstalment: (artworkId, transactionId) =>
    `${BASE_URL}artworks/${artworkId}/transactions/${transactionId}`,
  viewTransactions: (artwork_id) =>
    `${BASE_URL}artworks/${artwork_id}/transactions`,
  viewTransaction: (artworkId, transactionId) =>
    `${BASE_URL}artworks/${artworkId}/transactions/${transactionId}`,
  uploadTransactionFiles: (artwork_transactionId) =>
    `${BASE_URL}files/attachments/Transactions/${artwork_transactionId}`,
};

export const contactAPI = {
  populateAddContactDropdown: `${BASE_URL}contacts/template`,
  contactListDropdowns: `${BASE_URL}contacts/listing/template`,
  addContact: `${BASE_URL}contacts`,
  editContact: (id) => `${BASE_URL}contacts/${id}`,
  uploadFiles: (contact_id) =>
    `${BASE_URL}files/attachments/contacts/${contact_id}`,
  listContacts: (page, rowPerPage) =>
    `${BASE_URL}contacts?page=${page}&rowPerPage=${rowPerPage}`,
  viewContact: (id) => `${BASE_URL}contacts/${id}`,
  relatedArtworks: (contact_id, page, rowPerPage) =>
    `${BASE_URL}contacts/${contact_id}/artworks?page=${page}&rowPerPage=${rowPerPage}`,
  relatedLocations: (contact_id) =>
    `${BASE_URL}contacts/${contact_id}/locations`,
  deleteContact: (id) => `${BASE_URL}contacts/${id}`,
  archiveunarchiveContact: (id) =>
    `${BASE_URL}contacts/${id}`,
  exportContacts: `${BASE_URL}contacts/export`,
};

export const locationAPI = {
  listLocations: (page, rowPerPage, isRelatedEntityRequired) =>
    `${BASE_URL}locations?page=${page}&rowPerPage=${rowPerPage}&isRelatedEntityRequired=${isRelatedEntityRequired}`,
  populateAddLocationDropdown: `${BASE_URL}locations/template`,
  addLocation: `${BASE_URL}locations`,
  editLocation: (id) => `${BASE_URL}locations/${id}`,
  uploadFiles: (location_id) =>
    `${BASE_URL}files/attachments/locations/${location_id}`,
  viewLocation: (id) => `${BASE_URL}locations/${id}`,
  relatedContacts: (location_id) =>
    `${BASE_URL}locations/${location_id}/contacts`,
  relatedArtworks: (location_id, page, rowPerPage) =>
    `${BASE_URL}locations/${location_id}/artworks?page=${page}&rowPerPage=${rowPerPage}`,
  deleteLocation: (id) => `${BASE_URL}locations/${id}`,
  addLocationPremiumRate: `${BASE_URL}locations/premiumrate`,
};

export const movementAPI = {
  listOfMovemetns: (page, rowPerPage) =>
    `${BASE_URL}movements?page=${page}&rowPerPage=${rowPerPage}`,
  populateAddMovementsDropdown: `${BASE_URL}movements/template`,
  addMovement: `${BASE_URL}movements`,
  editMovement: (id) => `${BASE_URL}movements/${id}`,
  uploadFiles: (location_id) =>
    `${BASE_URL}files/attachments/movements/${location_id}`,
  viewMovement: (id, locationTemplate) =>
    `${BASE_URL}movements/${id}?locationTemplate=${locationTemplate}`,
  deleteMovement: (id) => `${BASE_URL}locations/${id}`,
  listOfMovementsByArtworkId: (artworkId, page, rowPerPage, isLatest) =>
    `${BASE_URL}artworks/${artworkId}/movements?page=${page}&rowPerPage=${rowPerPage}&isLatest=${isLatest}`,
  movementDropDownUpdateOnDate: (artworkId, date) =>
    `${BASE_URL}movements/${artworkId}/${date}`,
};

export const artworkValuationAPI = {
  viewValuations: (artwork_id, page, rowPerPage) =>
    `${BASE_URL}artworks/${artwork_id}/valuations?page=${page}&rowPerPage=${rowPerPage}`,
  addValuationTemplate: `${BASE_URL}valuations/template`,
  addValuation: `${BASE_URL}valuations`,
  editDeleteValuation: (id) => `${BASE_URL}valuations/${id}`,
  uploadFiles: (valuation_id) =>
    `${BASE_URL}files/attachments/valuations/${valuation_id}`,
  viewValuation: (id) => `${BASE_URL}valuations/${id}`,
  flagValuation: (artwork_id, isFlagged) => `${BASE_URL}valuations/flag/${artwork_id}?isFlagged=${isFlagged}`
};

export const statusAPI = {
  statusTemplate: `${BASE_URL}status/template`,
};

export const usersAPI = {
  getUsers: (page, rowPerPage) =>
    `${BASE_URL}users/?page=${page}&rowPerPage=${rowPerPage}`,
  createUser: `${BASE_URL}users`,
  userTemplate: `${BASE_URL}users/template`,
  viewUser: (id, userTemplate) =>
    `${BASE_URL}users/${id}?template=${userTemplate}`,
  updateUserStatus: (id, queryParam, status) =>
    `${BASE_URL}users/${id}?${queryParam}=${status}`,
};

export const artworkIncomeExpenseAPI = {
  viewIncomeExpenses: (artwork_id, page, rowPerPage) =>
    `${BASE_URL}artworks/${artwork_id}/income?page=${page}&rowPerPage=${rowPerPage}`,
  incomeExpenseTemplate: (allTemplates, isBulkAction) =>
    `${BASE_URL}income/template?allTemplates=${allTemplates}&isBulkAction=${isBulkAction} `,
  addIncomeExpense: `${BASE_URL}income`,
  addIncomesExpenses: `${BASE_URL}incomes`,
  editIncomeExpense: (id) => `${BASE_URL}income/${id}`,
  viewDeleteIncomeExpense: (id) => `${BASE_URL}income/${id}`,
  uploadFiles: (income_id) =>
    `${BASE_URL}files/attachments/income/${income_id}`,
};

export const groupsAPI = {
  groupTemplate: `${BASE_URL}groups/template`,
  getGroups: (page, rowPerPage) =>
    `${BASE_URL}groups/?page=${page}&rowPerPage=${rowPerPage}`,
  createGroup: `${BASE_URL}groups`,
  editGroup: (id) => `${BASE_URL}groups/${id}`,
  getGroup: (id, groupsTemplate) =>
    `${BASE_URL}groups/${id}?template=${groupsTemplate}`,
};

export const insurancePolicyAPI = {
  getAllInsurancePolicies: (page, rowPerPage) =>
    `${BASE_URL}insurancepolicy?page=${page}&rowPerPage=${rowPerPage}`,
  insurancePolicyTemplate: `${BASE_URL}insurancepolicy/template`,
  addInsurancePolicy: `${BASE_URL}insurancepolicy`,
  updateInsurancePolicy: (id) => `${BASE_URL}insurancepolicy/${id}`,
  viewInsurancePolicy: (id) => `${BASE_URL}insurancepolicy/${id}`,
  getInsurancePoliciesInsurances: (insurancePolicyId) =>
    `${BASE_URL}insurancepolicy/${insurancePolicyId}/insurances/`,
  uploadFiles: (insurance_id, entityType) =>
    `${BASE_URL}files/attachments/${entityType}/${insurance_id}`,
  getInsurancePremiumTemplate: (policyId, year) =>
    `${BASE_URL}premium/template/policy/${policyId}?year=${year}`,
  getPremiumExpenseTemplate: (policyId, ownerId, year) =>
    `${BASE_URL}premium/template/policy/${policyId}/owner/${ownerId}/year/${year}`,
  getPremiumAllocation: (policyId, ownerId, year) =>
    `${BASE_URL}premium/policy/${policyId}/owner/${ownerId}/years/${year}`,
  addInsurancePremium: `${BASE_URL}premium`,
  getInsurancePremiumExpense: (policyId) =>
    `${BASE_URL}premium/policy/${policyId}`,
  getPremiumExpenses: (policyId, ownerId, year) =>
    `${BASE_URL}premium/policy/${policyId}/insurancePremium?ownerId=${ownerId}&years=${year}`,
  getTotalInsurancePremium: (policyId, artworkIds, ownerId, year) =>
    `${BASE_URL}premium/policy/${policyId}/insurancePremiumPaid?ownerId=${ownerId}&year=${year}&artworkId=${artworkIds}`,
};

export const artworkInsuranceAPI = {
  artworkInsuranceTemplate: `${BASE_URL}artworks/insurance/template`,
  addArtworkInsurance: (artworkId) =>
    `${BASE_URL}artworks/${artworkId}/insurance`,
  updateArtworkInsurance: (artworkId, id) =>
    `${BASE_URL}artworks/${artworkId}/insurance/${id}`,
  getArtworkInsurance: (artworkId) =>
    `${BASE_URL}artworks/${artworkId}/insurance`,
  viewArtworkInsurance: (artworkId, id) =>
    `${BASE_URL}artworks/${artworkId}/insurance/${id}`,
  uploadFiles: (insurance_id) =>
    `${BASE_URL}files/attachments/Insurance/${insurance_id}`,
};

export const liborAPI = {
  getLiborRates: (page, rowPerPage) =>
    `${BASE_URL}liborrate/?page=${page}&rowPerPage=${rowPerPage}`,
  createLibor: `${BASE_URL}liborrate`,
  editLibor: (id) => `${BASE_URL}liborrate/${id}`,
  getLiborRate: (id) => `${BASE_URL}liborrate/${id}`,
  isLiborExpired: `${BASE_URL}liborrate/expired`,
};

export const artworkLoanAPI = {
  viewLoans: (artwork_id, page, rowPerPage) =>
    `${BASE_URL}artworks/${artwork_id}/loan?page=${page}&rowPerPage=${rowPerPage}`,
  loanTemplate: `${BASE_URL}artworks/loan/template`,
  addLoan: (artwork_id) => `${BASE_URL}artworks/${artwork_id}/loan`,
  editViewLoan: (artwork_id, id) =>
    `${BASE_URL}artworks/${artwork_id}/loan/${id}`,
  uploadFiles: (loan_id, type) =>
    `${BASE_URL}files/attachments/${type}/${loan_id}`,
  deleteLoan: (artwork_id, id, deleteInsurance) =>
    `${BASE_URL}artworks/${artwork_id}/loan/${id}?deleteInsurance=${deleteInsurance}`,
};

export const artworkExhibitionAPI = {
  getAllExhibitions: (artworkId, page, rowPerPage) =>
    `${BASE_URL}artworks/${artworkId}/exhibitions?page=${page}&rowPerPage=${rowPerPage}`,
  exhibitionTemplate: (artworkId) =>
    `${BASE_URL}artworks/${artworkId}/exhibitions/template`,
  addExhibition: (artworkId) => `${BASE_URL}artworks/${artworkId}/exhibitions`,
  ExhibitionPublicationRichTemplate: (artworkId, type) =>
    `${BASE_URL}artworks/${artworkId}/${type}`,
  editViewDeleteExhibition: (artworkId, exhibition_id) =>
    `${BASE_URL}artworks/${artworkId}/exhibitions/${exhibition_id}`,
  uploadExhibitionFiles: (exhibition_id) =>
    `${BASE_URL}files/attachments/exhibition/${exhibition_id}`,
};

export const artworkPublicationAPI = {
  getAllPublications: (artworkId, page, rowPerPage) =>
    `${BASE_URL}artworks/${artworkId}/publications?page=${page}&rowPerPage=${rowPerPage}`,
  publicationTemplate: `${BASE_URL}publications/template`,
  addPublication: `${BASE_URL}publications`,
  editViewDeletePublication: (publicationId) =>
    `${BASE_URL}publications/${publicationId}`,
  uploadPublicationFiles: (publication_id) =>
    `${BASE_URL}files/attachments/publication/${publication_id}`,
};

export const countryIptAPI = {
  getAllCountryIpts: `${BASE_URL}country/ipt/all`,
  getCountryIpt: (countryCode) =>
    `${BASE_URL}country/ipt?country=${countryCode}`,
  addCountryIpt: `${BASE_URL}country/ipt`,
  updateCountryIptByID: (countryIptId) =>
    `${BASE_URL}country/ipt/${countryIptId}`,
};

export const artworkReportAPI = {
  getTemplates: `${BASE_URL}artworks/reports/template`,
  getReport: (
    reportName,
    reportType,
    reportFormat,
    reportTheme,
    fields,
    sortBy
  ) =>
    `${BASE_URL}artworks/reports?reportName=${reportName}&reportType=${reportType}&fields=${fields}&sortBy=${sortBy}&reportFormat=${reportFormat}&reportTheme=${reportTheme}`,
};

export const globalSearchAPI = {
  search: (
    searchFor,
    page,
    rowPerPage,
    blockContactSearch
  ) =>
    `${BASE_URL}globalsearch/artworks/${searchFor}?page=${page}&rowPerPage=${rowPerPage}&blockContactSearch=${blockContactSearch}`,
};

export const dashboardAPI = {
  acqCost: `${BASE_URL}dashboard/acqcost`,
};

export const attachmentAPI = {
  getAttachments: (entityName, id) => `${BASE_URL}files/${entityName}/${id}`,
  artworkAttachmentList: (artwork_id, page, rowPerPage) =>
    `${BASE_URL}artworks/${artwork_id}/files?page=${page}&rowPerPage=${rowPerPage}`,
};

export const serverSideEventsAPI = {
  local: `${BASE_URL}incomes/files`,
  importIncomeExpense: (guidValue) =>
    `${BASE_URL}incomes/files?guid=${guidValue}`,
  progress: `${BASE_URL}events`,
};
