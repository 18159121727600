import ActionTypes from "../ActionTypes";
const { GLOBAL_SEARCH } = ActionTypes;

export const setSearchText = (text) => ({
  type: GLOBAL_SEARCH.SET_SEARCH_TEXT,
  payload: text,
});
export const setSelectedContacts = (list) => ({
  type: GLOBAL_SEARCH.SET_SELECTED_CONTACTS,
  payload: list,
});
export const setTotalContactNumber = (number) => ({
  type: GLOBAL_SEARCH.SET_TOTAL_CONTACT_NUMBER,
  payload: number,
});

export const setSearchResponseList = (list) => ({
  type: GLOBAL_SEARCH.SET_GLOBAL_SEARCH_LIST,
  payload: list,
});
