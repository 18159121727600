import { ActionTypes } from "../ActionTypes";

const { GROUPS_ACTIONS } = ActionTypes;

const INITIAL_STATE = {
  paginatedGroups: { count: 0, data: [] },
  group: null,
};

const GroupsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case GROUPS_ACTIONS.SET_GROUPS_LISTS:
      return {
        ...state,
        paginatedGroups: payload,
      };
    case GROUPS_ACTIONS.SET_GROUP:
      return {
        ...state,
        group: payload,
      };
    default:
      return state;
  }
};

export default GroupsReducer;
