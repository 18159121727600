export const ActionTypes = {
  USER_ACTIONS: {
    SET_CURRENT_USER_SESSION: "SET_CURRENT_USER_SESSION",
    SET_USERS_LISTS: "SET_USERS_LISTS",
    SET_ROLES_LISTS: "SET_ROLES_LISTS",
    SET_GROUPS_LISTS: "SET_GROUPS_LISTS",
    SET_USER: "SET_USER",
  },
  COMMON_ACTIONS: {
    SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
  },
  ARTWORKS: {
    ARTWORK_ADDED_SUCCESSFULLY: "ARTWORK_ADDED_SUCCESSFULLY",
    CLEARLY_ADDED_SUCCESSFULLY: "CLEARLY_ADDED_SUCCESSFULLY",
    SET_ARTWORK_LIST: "SET_ARTWORK_LIST",
    SET_ARTWORK_TRANSACTIONS_LIST: "SET_ARTWORK_TRANSACTIONS_LIST",
    SET_ARTWORK: "SET_ARTWORK",
    SET_REFRESH_ARTWORK: "SET_REFRESH_ARTWORK",
    SET_ARTWORK_ALLINCOST: "SET_ARTWORK_ALLINCOST",
    SET_REFRESH_ALLINCOST: "SET_REFRESH_ALLINCOST",
    SET_ARTWORK_STATUS_CHANGE_HISTORY: "SET_ARTWORK_STATUS_CHANGE_HISTORY",
    SET_ARTWORK_VALUATIONS_LIST: "SET_ARTWORK_VALUATIONS_LIST",
    SET_VALUATION_DETAIL_ID: "SET_VALUATION_DETAIL_ID",
    SET_VALUATION: "SET_VALUATION",
    SET_ARTWORK_INCOME_EXPENSE_LIST: "SET_ARTWORK_INCOME_EXPENSE_LIST",
    SET_INCOME_EXPENSE_DETAIL_ID: "SET_INCOME_EXPENSE_DETAIL_ID",
    SET_INCOME_EXPENSE: "SET_INCOME_EXPENSE",
    SET_ARTWORK_LOAN_LIST: "SET_ARTWORK_LOAN_LIST",
    SET_LOAN_DETAIL_ID: "SET_LOAN_DETAIL_ID",
    SET_LOAN: "SET_LOAN",
    SET_ARTWORK_EXHIBITION_PUBLICATION_LIST:
      "SET_ARTWORK_EXHIBITION_PUBLICATION_LIST",
    SET_EXHIBITION_PUBLICATION_DETAIL_ID:
      "SET_EXHIBITION_PUBLICATION_DETAIL_ID",
    SET_EXHIBITION_PUBLICATION: "SET_EXHIBITION_PUBLICATION",
    SET_EXHIBITION_RICH_TEXT: "SET_EXHIBITION_RICH_TEXT",
    SET_PUBLICATION_RICH_TEXT: "SET_PUBLICATION_RICH_TEXT",
    SET_ARTWORK_ATTACHMENT_LIST: "SET_ARTWORK_ATTACHMENT_LIST",
  },
  CONTACTS: {
    SET_CONTACT_LIST: "SET_CONTACT_LIST",
    SET_CONTACT: "SET_CONTACT",
    SET_CONTACT_DETAIL_ID: "SET_CONTACT_DETAIL_ID",
  },
  LOCATIONS: {
    SET_LOCATION_LIST: "SET_LOCATION_LIST",
    SET_LOCATION: "SET_LOCATION",
    SET_LOCATION_DETAIL_ID: "SET_LOCATION_DETAIL_ID",
  },
  MOVEMENTS: {
    SET_MOVEMENT_LIST: "SET_MOVEMENT_LIST",
    SET_MOVEMENT: "SET_MOVEMENT",
    SET_MOVEMENT_DETAIL_ID: "SET_MOVEMENT_DETAIL_ID",
  },
  GROUPS_ACTIONS: {
    SET_GROUPS_LISTS: "SET_GROUPS_LISTS",
    SET_GROUP: "SET_GROUP",
  },
  INSURANCE_POLICIES: {
    SET_INSURANCE_POLICY_LIST: "SET_INSURANCE_POLICY_LIST",
    SET_INSURANCE_POLICY: "SET_INSURANCE_POLICY",
    SET_INSURANCE_POLICY_DETAIL_ID: "SET_INSURANCE_POLICY_DETAIL_ID",
    ADD_PREMIUM_EXPENSE: "ADD_PREMIUM_EXPENSE",
  },
  LIBOR_RATE: {
    SET_LIBOR_RATES_LIST: "SET_LIBOR_RATES_LIST",
    SET_IS_LIBOR_EXPIRED: "SET_IS_LIBOR_EXPIRED",
  },
  MARKETPLACE: {
    SET_MARKETPLACE_LIST: "SET_MARKETPLACE_LIST",
    SET_MARKETPLACE_ARTWORK: "SET_MARKETPLACE_ARTWORK",
    SET_ARTWORK_INCOLLECTION: "SET_ARTWORK_INCOLLECTION",
  },
  COUNTRY_IPT_RATE: {
    SET_COUNTRY_IPT_RATE_LIST: "SET_COUNTRY_IPT_RATE_LIST",
  },
  GLOBAL_SEARCH: {
    SET_SEARCH_TEXT: "SET_SEARCH_TEXT",
    SET_GLOBAL_SEARCH_LIST: "SET_GLOBAL_SEARCH_LIST",
    SET_SELECTED_CONTACTS: "SET_SELECTED_CONTACTS",
    SET_TOTAL_CONTACT_NUMBER: "SET_TOTAL_CONTACT_NUMBER",
  },
};

export default ActionTypes;
