import { ActionTypes } from "../ActionTypes";

const { LOCATIONS } = ActionTypes;

const INITIAL_STATE = {
    location: {},
    paginatedLocations: {},
    locationDetailId: null
};

const LocationReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case LOCATIONS.SET_LOCATION_LIST:
            return {
                ...state,
                paginatedLocations: payload
            };
        case LOCATIONS.SET_LOCATION_DETAIL_ID:
            return {
                ...state,
                locationDetailId: payload,
            };
        case LOCATIONS.SET_LOCATION:
            return {
                ...state,
                location: payload
            };
        default:
            return state;
    }
}

export default LocationReducer;
