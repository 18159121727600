export const conf = window.ENV_CONFIG || process.env;
export const AppConfig = {
  COGNITO: {
    UserPoolBaseUri: conf.REACT_APP_COGNITO_BASE_URL,
    ClientId: conf.REACT_APP_COGNITO_CLIENT_ID,
    CallbackUri: window.location.origin,
    SignoutUri: window.location.origin,
  },
  BASE_URL: conf.REACT_APP_BASE_URL,
  APP_ENV: conf.REACT_APP_ENV
}

export default AppConfig;