import { lazy } from "react";
import { RouteNames, UserRoles } from "../utils";

const Dashboard = lazy(() => import("../view/dashboard/dashboard.component"));
const ListArtWork = lazy(() =>
  import("../view/artwork/list-artwork/list-artwork.component")
);
const AddArtWork = lazy(() =>
  import("../view/artwork/add-artwork/add-artwork-collection.component")
);
const ViewArtwork = lazy(() =>
  import("../view/artwork/view-artwork/view-artwork.component")
);
const EditArtwrok = lazy(() =>
  import("../view/artwork/edit-artwork/edit-artwork-collection.component")
);
const AddArtworkTransaction = lazy(() =>
  import(
    "../view/artwork/view-artwork/financials-tab/artwork-transaction/add-artwork-transactions/add-artwork-transactions.component"
  )
);
const EditArtworkTransaction = lazy(() =>
  import(
    "../view/artwork/view-artwork/financials-tab/artwork-transaction/edit-artwork-transaction/edit-artwork-transaction.component"
  )
);
const ArtworkTransactionSummary = lazy(() =>
  import(
    "../view/artwork/view-artwork/financials-tab/artwork-transaction-summary/artwork-transaction-summary.component"
  )
);
const AddArtworkValuation = lazy(() =>
  import(
    "../view/artwork/view-artwork/valuation-tab/artwork-valuation/add-artwork-valuation/add-artwork-valuation.component"
  )
);
const EditArtworkValuation = lazy(() =>
  import(
    "../view/artwork/view-artwork/valuation-tab/artwork-valuation/edit-artwork-valuation/edit-artwork-valuation.component"
  )
);

const AddArtworkLoan = lazy(() =>
  import("../view/artwork/view-artwork/loan-tab/add-loan/add-loan.component")
);
const EditArtworkLoan = lazy(() =>
  import("../view/artwork/view-artwork/loan-tab/edit-loan/edit-loan.component")
);

const ArtworkValuationSummary = lazy(() =>
  import(
    "../view/artwork/view-artwork/valuation-tab/artwork-valuation-summary/artwork-valuation-summary.component.jsx"
  )
);
const ContactsList = lazy(() =>
  import("../view/contacts/contacts-list/contacts-list.component")
);
const AddContact = lazy(() =>
  import("../view/contacts/add-contacts/add-contact.component")
);
const EditContact = lazy(() =>
  import("../view/contacts/edit-contact/edit-contact.component")
);

const ListingTabs = lazy(() =>
  import("../view/locations/listing-tabs/listing-tabs.component")
);

const AddLocation = lazy(() =>
  import("../view/locations/add-location/add-location.component")
);
const EditLocation = lazy(() =>
  import("../view/locations/edit-location/edit-location.component")
);
const AddArtworkMovement = lazy(() =>
  import(
    "../view/artwork/view-artwork/movements-tab/add-movement/add-movment.component"
  )
);
const AddMovement = lazy(() =>
  import("../view/movements/add-movement/add-movement.component")
);
const EditMovement = lazy(() =>
  import("../view/movements/edit-movement/edit-movement.component")
);
const AddBulkArtworkIncomeExpense = lazy(() =>
  import(
    "../view/artwork/view-artwork/income-expense-tab/add-bulk-income-expense/add-bulk-income-expense.component"
  )
);
const AddArtworkIncomeExpense = lazy(() =>
  import(
    "../view/artwork/view-artwork/income-expense-tab/add-income-expense/add-income-expense.component"
  )
);
const EditArtworkIncomeExpense = lazy(() =>
  import(
    "../view/artwork/view-artwork/income-expense-tab/edit-income-expense/edit-income-expense.component"
  )
);

const AddArtworkMention = lazy(() =>
  import(
    "../view/artwork/view-artwork/LiteratureExhibitionsTab/add-literature-exhibition/add-literature-exhibition.component"
  )
);
const EditArtworkMention = lazy(() =>
  import(
    "../view/artwork/view-artwork/LiteratureExhibitionsTab/edit-literature-exhibition/edit-literature-exhibition.component"
  )
);

const SettingsListingTab = lazy(() =>
  import(
    "../view/settings/settings-listing-tabs/settings-listing-tabs.component"
  )
);
const UserDetail = lazy(() =>
  import("../view/settings/user-management/user-details/user-details.component")
);
const AddGroup = lazy(() =>
  import("../view/settings/groups/add-group/add-group.component")
);
const EditGroup = lazy(() =>
  import("../view/settings/groups/edit-group/edit-group.component")
);

const InsurancePolicyList = lazy(() =>
  import("../view/insurance-policy/insurance-list/insurance-list.compnent")
);
const AddInsurancePolicy = lazy(() =>
  import("../view/insurance-policy/add-insurance/add-insurance.component")
);
const EditInsurancePolicy = lazy(() =>
  import("../view/insurance-policy/edit-insurance/edit-insurance-component")
);
const InsuranceDetails = lazy(() =>
  import(
    "../view/insurance-policy/insurance-details/insurance-details.component"
  )
);
const AddArtworkInsurance = lazy(() =>
  import(
    "../view/artwork/view-artwork/insurance/add-artwork-insurance/add-artwork-insurance.component"
  )
);
const EditArtworkInsurance = lazy(() =>
  import(
    "../view/artwork/view-artwork/insurance/edit-artwork-insurance/edit-artwork-insurance.component"
  )
);
const ArtworkInsuranceDetail = lazy(() =>
  import(
    "../view/artwork/view-artwork/insurance/artwork-insurance-details/artwork-insurance-details.component"
  )
);
const ListMarketplace = lazy(() =>
  import("../view/marketplace/list-marketplace/list-marketplace.component")
);
const ViewMarketplace = lazy(() =>
  import("../view/marketplace/view-marketplace/view-marketplace.component")
);
const AddInsurancePremium = lazy(() =>
  import(
    "../view/insurance-policy/insurance-premium/add-insurance-premium/add-insurance-premium.component"
  )
);
const AddPremiumExpense = lazy(() =>
  import(
    "../view/insurance-policy/insurance-premium/premium-expense-form/premium-expense-form.component"
  )
);

const AddMarketPlaceArtWork = lazy(() =>
  import("../view/marketplace/add-artwork/add-artwork-market-place.component")
);
const EditMarketPlaceArtWork = lazy(() =>
  import("../view/marketplace/edit-artwork/edit-artwork-market-place.component")
);
const ArtworkReport = lazy(() =>
  import("../view/artwork/artwork-reports/artwork-report.component")
);
const GlobalSearch = lazy(() =>
  import("../view/search/global-search.component")
);
const ImportIncomeExpense = lazy(() =>
  import(
    "../view/artwork/list-artwork/bulk-actions/import-income-expense/import-income-expense.component"
  )
);

const routesList = {
  Dashboard: {
    path: RouteNames.Dashboard,
    component: Dashboard,
    roles: UserRoles.AllRoles,
  },
  ListArtWork: {
    path: RouteNames.ListArtWork,
    component: ListArtWork,
    roles: UserRoles.AllRoles,
  },
  AddArtWork: {
    path: RouteNames.AddArtWork,
    component: AddArtWork,
    roles: UserRoles.AllRoles,
  },
  ViewArtwork: {
    path: RouteNames.ViewArtwork.path,
    component: ViewArtwork,
    roles: UserRoles.AllRoles,
  },
  EditArtwork: {
    path: RouteNames.EditArtwork.path,
    component: EditArtwrok,
    roles: UserRoles.AllRoles,
  },
  AddArtworkTransaction: {
    path: RouteNames.AddArtworkTransaction.path,
    component: AddArtworkTransaction,
    roles: UserRoles.AllRoles,
  },
  EditArtworkTransaction: {
    path: RouteNames.EditArtworkTransaction.path,
    component: EditArtworkTransaction,
    roles: UserRoles.AllRoles,
  },
  ArtworkTransactionSummary: {
    path: RouteNames.ArtworkTransactionSummary.path,
    component: ArtworkTransactionSummary,
    roles: UserRoles.AllRoles,
  },
  AddArtworkValuation: {
    path: RouteNames.AddArtworkValuation.path,
    component: AddArtworkValuation,
    roles: UserRoles.AllRoles,
  },
  EditArtworkValuation: {
    path: RouteNames.EditArtworkValuation.path,
    component: EditArtworkValuation,
    roles: UserRoles.AllRoles,
  },
  AddArtworkLoan: {
    path: RouteNames.AddArtworkLoan.path,
    component: AddArtworkLoan,
    roles: UserRoles.AllRoles,
  },
  EditArtworkLoan: {
    path: RouteNames.EditArtworkLoan.path,
    component: EditArtworkLoan,
    roles: UserRoles.AllRoles,
  },
  ArtworkValuationSummary: {
    path: RouteNames.ArtworkValuationSummary.path,
    component: ArtworkValuationSummary,
    roles: UserRoles.AllRoles,
  },
  ContactsList: {
    path: RouteNames.ContactsList,
    component: ContactsList,
    roles: UserRoles.AllRoles,
  },
  AddContact: {
    path: RouteNames.AddContact,
    component: AddContact,
    roles: UserRoles.AllRoles,
  },
  EditContact: {
    path: RouteNames.EditContact.path,
    component: EditContact,
    roles: UserRoles.AllRoles,
  },
  LocationsList: {
    path: RouteNames.LocationsList,
    component: ListingTabs,
    roles: UserRoles.AllRoles,
  },
  AddLocation: {
    path: RouteNames.AddLocation,
    component: AddLocation,
    roles: UserRoles.AllRoles,
  },
  EditLocation: {
    path: RouteNames.EditLocation.path,
    component: EditLocation,
    roles: UserRoles.AllRoles,
  },
  MovementsList: {
    path: RouteNames.MovementsList,
    component: ListingTabs,
    roles: UserRoles.AllRoles,
  },

  AddMovement: {
    path: RouteNames.AddMovement,
    component: AddMovement,
    roles: UserRoles.AllRoles,
  },

  AddArtworkMovement: {
    path: RouteNames.AddArtworkMovement.path,
    component: AddArtworkMovement,
    roles: UserRoles.AllRoles,
  },

  EditMovement: {
    path: RouteNames.EditMovement.path,
    component: EditMovement,
    roles: UserRoles.AllRoles,
  },
  UsersList: {
    path: RouteNames.UsersList,
    component: SettingsListingTab,
    roles: [UserRoles.COLLECTION_MANAGER],
  },
  UserDetail: {
    path: RouteNames.UserDetail.path,
    component: UserDetail,
    roles: [UserRoles.COLLECTION_MANAGER],
  }, 
  AddBulkArtworkIncomeExpense: {
    path: RouteNames.AddBulkArtworkIncomeExpense.path,
    component: AddBulkArtworkIncomeExpense,
    roles: UserRoles.AllRoles,
  },
  AddArtworkIncomeExpense: {
    path: RouteNames.AddArtworkIncomeExpense.path,
    component: AddArtworkIncomeExpense,
    roles: UserRoles.AllRoles,
  },
  EditArtworkIncomeExpense: {
    path: RouteNames.EditArtworkIncomeExpense.path,
    component: EditArtworkIncomeExpense,
    roles: UserRoles.AllRoles,
  },
  AddArtworkMentions: {
    path: RouteNames.AddArtworkMention.path,
    component: AddArtworkMention,
    roles: UserRoles.AllRoles,
  },
  EditArtworkMentions: {
    path: RouteNames.EditArtworkMention.path,
    component: EditArtworkMention,
    roles: UserRoles.AllRoles,
  },
  GroupsList: {
    path: RouteNames.GroupList,
    component: SettingsListingTab,
    roles: [UserRoles.COLLECTION_MANAGER],
  },
  AddGroup: {
    path: RouteNames.AddGroup,
    component: AddGroup,
    roles: [UserRoles.COLLECTION_MANAGER],
  },
  EditGroup: {
    path: RouteNames.EditGroup.path,
    component: EditGroup,
    roles: [UserRoles.COLLECTION_MANAGER],
  },
  InsurancePolicyList: {
    path: RouteNames.InsurancePolicyList,
    component: InsurancePolicyList,
    roles: UserRoles.AllRoles,
  },
  AddInsurancePolicy: {
    path: RouteNames.AddInsurancePolicy,
    component: AddInsurancePolicy,
    roles: [UserRoles.COLLECTION_MANAGER],
  },
  EditInsurancePolicy: {
    path: RouteNames.EditInsurancePolicy.path,
    component: EditInsurancePolicy,
    roles: [UserRoles.COLLECTION_MANAGER],
  },
  InsurancePolicyDetail: {
    path: RouteNames.InsurancePolicyDetail.path,
    component: InsuranceDetails,
    roles: UserRoles.AllRoles,
  },
  AddArtworkInsurance: {
    path: RouteNames.AddArtworkInsurance.path,
    component: AddArtworkInsurance,
    roles: UserRoles.AllRoles,
  },
  EditArtworkInsurance: {
    path: RouteNames.EditArtworkInsurance.path,
    component: EditArtworkInsurance,
    roles: UserRoles.AllRoles,
  },
  ArtworkInsuranceDetail: {
    path: RouteNames.ArtworkInsuranceDetail.path,
    component: ArtworkInsuranceDetail,
    roles: UserRoles.AllRoles,
  },
  LiborList: {
    path: RouteNames.LiborList,
    component: SettingsListingTab,
    roles: UserRoles.AllRoles,
  },
  LiborDetail: {
    path: RouteNames.LiborDetail.path,
    component: SettingsListingTab,
    roles: [UserRoles.COLLECTION_MANAGER],
  },
  AddMarketPlaceArtWork: {
    path: RouteNames.AddMarketPlaceArtWork,
    component: AddMarketPlaceArtWork,
    roles: UserRoles.AllRoles,
  },
  ListMarketplace: {
    path: RouteNames.ListMarketplace,
    component: ListMarketplace,
    roles: UserRoles.AllRoles,
  },
  ViewMarketplace: {
    path: RouteNames.ViewMarketplace.path,
    component: ViewMarketplace,
    roles: UserRoles.AllRoles,
  },
  EditMarketPlaceArtWork: {
    path: RouteNames.EditMarketPlaceArtWork.path,
    component: EditMarketPlaceArtWork,
    roles: UserRoles.AllRoles,
  },
  IptList: {
    path: RouteNames.IptList,
    component: SettingsListingTab,
    roles: [UserRoles.COLLECTION_MANAGER],
  },
  IptDetail: {
    path: RouteNames.IptDetail.path,
    component: SettingsListingTab,
    roles: [UserRoles.COLLECTION_MANAGER],
  },
  AddInsurancePremium: {
    path: RouteNames.AddInsurancePremium.path,
    component: AddInsurancePremium,
    roles: [UserRoles.COLLECTION_MANAGER],
  },
  AddPremiumExpense: {
    path: RouteNames.AddPremiumExpense.path,
    component: AddPremiumExpense,
    roles: [UserRoles.COLLECTION_MANAGER],
  },
  GenerateArtworkReport: {
    path: RouteNames.GenerateArtworkReport,
    component: ArtworkReport,
    roles: UserRoles.AllRoles,
  },
  EditPremiumExpense: {
    path: RouteNames.EditPremiumExpense.path,
    component: AddPremiumExpense,
    roles: [UserRoles.COLLECTION_MANAGER],
  },
  GlobalSearch: {
    path: RouteNames.GlobalSearch,
    component: GlobalSearch,
    roles: UserRoles.AllRoles,
  },
  ImportExpenseIncome: {
    path: RouteNames.ImportExpenseIncome,
    component: ImportIncomeExpense,
    roles: [UserRoles.COLLECTION_MANAGER],
  },
};

export default routesList;
