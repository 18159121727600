import axios from "axios";
import AppConfig from "../config/AppConfig";
import {
  logoutUrl,
  getToken,
  removeTokens,
  getUsername,
  getRefreshToken,
} from "../lib/CognitoUtils";
import { getNewToken } from "./api/authService";

const { Base_Url } = AppConfig;

const client = axios.create({
  responseType: "json",
  baseURL: Base_Url,
  transformResponse: (response) => response,
});

client.defaults.headers.post["Content-Type"] = "application/json";
client.defaults.headers.post.Accept = "application/json";
client.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
client.defaults.headers.pragma = "no-cache";
client.defaults.timeout = 60000 * 5;
/*
 * we have report generation feature having images in doc and pdf reports
 * so set min resp wait time to 5min */

client.interceptors.response.use(
  (response) => {
    // return the successful reponse back to the calling service
    return response;
  },
  (error) => {
    // Return error which is not due to authentication back to the calling service
    // Logout user if token refresh didn't work
    if (error.config.url.indexOf("/auth/refreshToken/") > -1) {
      logoutUser();
      return Promise.reject(error);
    }

    // if no jwt token then 403 access denied
    // if expired jwt then 401
    if (error.response.status !== 403 && error.response.status !== 401) {
      return Promise.reject(error);
    } else if (error.response.status === 403 && (error.config.url.includes('allincost') || error.config.url.includes('/files/'))) {
      return Promise.reject(error)
    }

    // Try sending request again with new token
    const username = getUsername();
    const refreshToken = getRefreshToken();
    return getNewToken(username, refreshToken)
      .then((token) => {
        // new request with new token
        const config = error.config;
        config.headers["Authorization"] = `Bearer ${token}`;

        return client.request(config);
      })
      .catch((err) => {
        // Getting refresh token failed  logout user
        logoutUser();
        Promise.reject(err);
      });
  }
);

const logoutUser = () => {
  removeTokens();
  window.location.assign(logoutUrl);
};

const request = (options, requestTimeout = 300000) => {
  var token = getToken();

  client.defaults.timeout = requestTimeout;

  if (token) {
    client.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete client.defaults.headers.common.Authorization;
  }

  const onSuccess = (response) => {
    try {
      if (typeof response.data === "string") return JSON.parse(response.data);
      else return response.data;
    } catch (err) {
      return response.data;
    }
  };

  const onFailure = (error) => {
    return Promise.reject(error);
  };

  return client(options).then(onSuccess).catch(onFailure);
};

export default request;
