import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as CollectionIcon } from "../../assets/side_nav/collection_icon.svg";
import { ReactComponent as ContactsIcon } from "../../assets/side_nav/contacts_icon.svg";
import { ReactComponent as DashboardIcon } from "../../assets/side_nav/dashboard_icon.svg";
import { ReactComponent as LocationIcon } from "../../assets/side_nav/location_icon.svg";
import { ReactComponent as SettingIcon } from "../../assets/side_nav/settings_icon.svg";
import { ReactComponent as InsurancePolicyIcon } from "../../assets/side_nav/insurance_policies_icon.svg";
import { ReactComponent as MarketplaceIcon } from "../../assets/side_nav/marketplace_icon.svg";
import { selectCurrentUser } from "../../redux/user/user.selector";
import routesList from "../../routes/routes-list";
import { UserRoles } from "../../utils";
import AppConfig from "../../config/AppConfig";
import { AppEnv } from "../../utils/Constants";

const { APP_ENV } = AppConfig;

const SideMenu = ({ onItemClick }) => {
  const currentUser = useSelector(selectCurrentUser, shallowEqual);
  const items = [
    {
      key: "1",
      label: "Dashboard",
      path: routesList.Dashboard.path,
      icon: <DashboardIcon />,
      iconClassName: "anticon anticon-appstore",
      showInNav: true,
      roles: routesList.Dashboard.roles,
      disabled: AppEnv.PROD === APP_ENV,
    },
    {
      key: "2",
      label: "Collection",
      path: routesList.ListArtWork.path,
      icon: <CollectionIcon />,
      iconClassName: "anticon anticon-appstore",
      showInNav: true,
      roles: routesList.ListArtWork.roles,
    },
    {
      key: "2",
      path: routesList.AddArtWork.path,
      showInNav: false,
    },
    {
      key: "2",
      path: routesList.EditArtwork.path,
      showInNav: false,
    },
    {
      key: "2",
      path: routesList.ViewArtwork.path,
      showInNav: false,
    },
    {
      key: "2",
      path: routesList.AddArtworkTransaction.path,
      showInNav: false,
    },
    {
      key: "2",
      path: routesList.EditArtworkTransaction.path,
      showInNav: false,
    },
    {
      key: "2",
      path: routesList.AddBulkArtworkIncomeExpense.path,
      showInNav: false,
    },
    {
      key: "2",
      path: routesList.AddArtworkIncomeExpense.path,
      showInNav: false,
    },
    {
      key: "2",
      path: routesList.EditArtworkIncomeExpense.path,
      showInNav: false,
    },
    {
      key: "2",
      path: routesList.AddArtworkMentions.path,
      showInNav: false,
    },
    {
      key: "2",
      path: routesList.EditArtworkMentions.path,
      showInNav: false,
    },
    {
      key: "2",
      path: routesList.AddArtworkInsurance.path,
      showInNav: false,
    },
    {
      key: "2",
      path: routesList.EditArtworkInsurance.path,
      showInNav: false,
    },
    {
      key: "2",
      path: routesList.AddArtworkLoan.path,
      showInNav: false,
    },
    {
      key: "2",
      path: routesList.EditArtworkLoan.path,
      showInNav: false,
    },
    {
      key: "2",
      path: routesList.AddArtworkMovement.path,
      showInNav: false,
    },
    {
      key: "2",
      path: routesList.ImportExpenseIncome.path,
      showInNav: false,
    },
    {
      key: "3",
      label: "Locations",
      path: routesList.LocationsList.path,
      icon: <LocationIcon />,
      iconClassName: "anticon anticon-appstore",
      showInNav: true,
      roles: routesList.LocationsList.roles,
    },
    {
      key: "3",
      path: routesList.AddLocation.path,
      showInNav: false,
    },
    {
      key: "3",
      path: routesList.EditLocation.path,
      showInNav: false,
    },
    {
      key: "3",
      label: "Movements",
      path: routesList.MovementsList.path,
      showInNav: false,
    },
    {
      key: "3",
      path: routesList.AddMovement.path,
      showInNav: false,
    },
    {
      key: "3",
      path: routesList.EditMovement.path,
      showInNav: false,
    },
    {
      key: "4",
      label: "Contacts",
      path: routesList.ContactsList.path,
      icon: <ContactsIcon />,
      iconClassName: "anticon anticon-appstore",
      showInNav: true,
      roles: routesList.ContactsList.roles,
    },
    {
      key: "4",
      path: routesList.AddContact.path,
      showInNav: false,
    },
    {
      key: "4",
      path: routesList.EditContact.path,
      showInNav: false,
    },
    {
      key: "5",
      label: "Insurance Policies",
      path: routesList.InsurancePolicyList.path,
      icon: <InsurancePolicyIcon />,
      iconClassName: "anticon anticon-appstore",
      showInNav: true,
      roles: routesList.InsurancePolicyList.roles,
    },
    {
      key: "5",
      path: routesList.AddInsurancePolicy.path,
      showInNav: false,
    },
    {
      key: "5",
      path: routesList.EditInsurancePolicy.path,
      showInNav: false,
    },
    {
      key: "5",
      path: routesList.InsurancePolicyDetail.path,
      showInNav: false,
    },
    {
      key: "5",
      path: routesList.AddPremiumExpense.path,
      showInNav: false,
    },
    {
      key: "5",
      path: routesList.AddInsurancePremium.path,
      showInNav: false,
    },
    {
      key: "5",
      path: routesList.EditPremiumExpense.path,
      showInNav: false,
    },
    {
      key: "6",
      label: "Marketplace",
      path: routesList.ListMarketplace.path,
      icon: <MarketplaceIcon />,
      iconClassName: "anticon anticon-appstore",
      showInNav: true,
      roles: routesList.ListMarketplace.roles,
    },
    {
      key: "6",
      path: routesList.ViewMarketplace.path,
      showInNav: false,
    },
    {
      key: "6",
      path: routesList.AddMarketPlaceArtWork.path,
      showInNav: false,
    },
    {
      key: "7",
      label: "Settings",
      path:
        currentUser?.role == UserRoles.COLLECTION_MANAGER
          ? routesList.UsersList.path
          : routesList.LiborList.path,
      icon: <SettingIcon />,
      iconClassName: "anticon anticon-appstore",
      showInNav: true,
      roles: routesList.LiborList.roles,
    },
    {
      key: "7",
      path: routesList.UserDetail.path,
      showInNav: false,
    },
    {
      key: "7",
      path: routesList.GroupsList.path,
      showInNav: false,
    },
    {
      key: "7",
      path: routesList.AddGroup.path,
      showInNav: false,
    },
    {
      key: "7",
      path: routesList.EditGroup.path,
      showInNav: false,
    },
    {
      key: "7",
      path: routesList.LiborList.path,
      showInNav: false,
    },
    {
      key: "7",
      path: routesList.LiborDetail.path,
      showInNav: false,
    },
    {
      key: "7",
      path: routesList.IptList.path,
      showInNav: false,
    },
    {
      key: "7",
      path: routesList.IptDetail.path,
      showInNav: false,
    },
  ];

  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState(items[0].key);

  const getCurrentRouteKey = () => {
    const pathName = `/${location.pathname.split("/")[1]}`;
    const selectedItem = items.find(
      (_item) => _item.path.indexOf(pathName) > -1
    );
    if (selectedItem) {
      return selectedItem.key;
    }
    return items[0].key;
  };

  useEffect(() => {
    setSelectedKey(getCurrentRouteKey());
  }, [location]);

  return (
    <Menu theme="dark" mode="inline" selectedKeys={[selectedKey]}>
      {items
        .filter((x) => x.showInNav && x.roles.includes(currentUser.role))
        .map((item, i) => (
          <Menu.Item
            key={item.key}
            disabled={item.disabled}
            onClick={onItemClick}
            icon={
              item.iconClassName ? (
                <span className={item.iconClassName}>{item.icon}</span>
              ) : (
                item.icon
              )
            }
          >
            <Link to={item.path}>{item.label}</Link>
          </Menu.Item>
        ))}
    </Menu>
  );
};

export default SideMenu;
