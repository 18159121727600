import { Layout } from "antd";
import React, { Suspense, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import "./App.css";
import Spinner from "./components/common/spinner/spinner.component";
import SideBar from "./components/side-bar/side-bar.component";
import TopBar from "./components/top-bar/top-bar.component";
import {
  getRefreshToken,
  getToken,
  isTokenExpired,
  loginUrl,
  setTokens,
} from "./lib/CognitoUtils";
import { setIsLiborRateExpired } from "./redux/libor-rate/libor-rate.action";
import { setCurrentUser } from "./redux/user/user.action";
import { selectCurrentUser } from "./redux/user/user.selector";
import Routes from "./routes/routes";
import { authService } from "./services";
import { getNewToken } from "./services/api/authService";
import { isLiborRateExpired } from "./services/api/liborService";
import "./styles/common.css";
import "./styles/forms.css";
import { UserRoles } from "./utils";
import LiborRateHelper from "./view/settings/libor-rate/LiborRateHelper";

const params = new URL(document.location).searchParams;
const code = params.get("code");
const user_access_token = getToken();

const { Content } = Layout;
const { getAccessToken } = authService;

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(
    user_access_token !== null
  );
  const [authenticating, setAuthenticating] = useState(code !== null);
  const [collapsed, setCollapsed] = useState(false);

  const tabSmallResizeQuery = window.matchMedia("all and (max-width: 1024px)");
  const tabLargeResizeQuery = window.matchMedia("all and (min-width: 1024px)");
  const currentUser = useSelector(selectCurrentUser, shallowEqual);
  const dispatch = useDispatch();

  const outsideSideBarHandler = (event) => {
    const sideBarDiv = document.getElementsByClassName("sideBarMain");
    const isClickInside = sideBarDiv[0].contains(event.target);
    if (!isClickInside && tabSmallResizeQuery.matches) {
      setCollapsed(true);
    }
  };

  const resizeSideBarHandler = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    tabLargeResizeQuery.addListener(resizeSideBarHandler);
    document.addEventListener("click", outsideSideBarHandler);

    const validateCode = async () => {
      window.history.replaceState({}, document.title, "/");
      setAuthenticating(false);
      getAccessToken(code)
        .then((res) => {
          const { access_token, id_token, user, refresh_token } = res.data;
          setTokens(access_token, id_token, user, refresh_token);
          // setLoginUser(user);
          setLoggedUser(user);
          setAuthenticated(true);
          if (
            user &&
            user?.role &&
            user?.role === UserRoles.COLLECTION_MANAGER
          ) {
            LiborRateHelper.checkLiborRate(dispatch);
          }

          setIsLoading(false);
        })
        .catch((error) => {
          console.log('error : ', error)
          // window.location.assign(loginUrl);
        });
    };
    if (!authenticated && !authenticating) {
      window.location.assign(loginUrl);
    } else if (code !== null && authenticating) {
      validateCode();
    } else {
      const token_expired = isTokenExpired(user_access_token);
      const refreshToken = getRefreshToken();
      if (token_expired && currentUser) {
        getNewToken(currentUser.username, refreshToken)
          .then((response) => {
            setIsLoading(false);
            // setLoginUser(getUserInfo());
          })
          .catch((error) => {
            window.location.assign(loginUrl);
          });
      } else {
        setIsLoading(false);
        // setLoginUser(userInfo);
      }
    }

    return () => {
      document.removeEventListener("click", outsideSideBarHandler);
      tabLargeResizeQuery.removeListener(resizeSideBarHandler);
    };
  }, []);

  // const setLoginUser = (payload) => {
  //   const user = {
  //     email: payload.email,
  //     name: `${payload.given_name} ${payload.family_name}`,
  //     id: payload.sub,
  //     role: payload.role,
  //   };
  //   dispatch(setCurrentUser(user));
  // };

  const setLoggedUser = (payload) => {
    const user = {
      email: payload.email,
      name: `${payload.given_name} ${payload.family_name}`,
      id: payload.sub,
      role: payload.role,
      username: payload.username,
    };
    dispatch(setCurrentUser(user));
  };

  const onCollapsed = (value) => {
    console.log('Collapsed value', value)
    setCollapsed(value);
  };

  let sideBarStyle = {};
  if (tabSmallResizeQuery.matches && !collapsed) {
    sideBarStyle = { position: "absolute", zIndex: "999", height: "100%" };
  }

  return isLoading ? (
    <Spinner />
  ) : (
    <Layout style={{ position: "relative" }} onClick={() => {}}>
      <SideBar
        collapsed={collapsed}
        collapsedToggle={onCollapsed}
        style={sideBarStyle}
        setCollapsed={setCollapsed}
      />
      <Layout className="site-layout">
        <TopBar collapsed={collapsed} collapsedToggle={onCollapsed} />
        <Content
          className="site-layout-back"
          style={{
            margin: "0px 0px",
            padding: 0,
            minHeight: 280,
          }}
        >
          <Suspense fallback={<Spinner />}>
            <Routes />
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
