import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import SideMenu from "../side-menu/side-menu.component";
import PropTypes from "prop-types";

import "./side-bar.style.css";

const { Sider } = Layout;

const SideBar = ({ collapsed, collapsedToggle, style, setCollapsed }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to handle window resize and update isMobile state
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    // Initial check for mobile
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMenuClick = () => {
    if (isMobile) {
      setCollapsed(true);
    }
  };

  return (
    
    <Sider
      className="sideBarMain"
      width={256}
      breakpoint="lg"
      trigger={null}
      collapsible
      collapsed={collapsed}
      onBreakpoint={collapsedToggle}
      collapsedWidth={isMobile ? 0 : 80}
      //collapsedWidth={0}
      //trigger={null}
      style={style}
    >
      <div>
        <h3 className="collection-title">
          {collapsed ? "GC" : "Global Collection"}
        </h3>
      </div>
      <SideMenu onItemClick={handleMenuClick} />
    </Sider>
    
  );
};

SideBar.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  collapsedToggle: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default SideBar;
