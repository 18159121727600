export const NumericRegex = /^[0-9]*$/g;
export const AlphaNumericRegex = /^[a-zA-Z0-9\s]*$/;
export const AlphaNumericPunctuationRegex = /^[a-zA-Z0-9.',()!?{}[\]\s]*$/;
export const AmountRegex = /^\d?(?:[\d,]{0,9})(?:\.\d{1,2})?$/; //numeric amount(can be comma separated with max preicision of 10 and scale of 2)
export const HtmlTagRegex = /<.+?>/g;
export const FloatWithEightDecimalRegex = /^\d?(?:[\d,]{0,9})(?:\.\d{1,8})?$/;
export const FloatTwoDigitWithEightDecimalRegex =
  /^\d?(?:[\d,]{0,1})(?:\.\d{1,8})?$/;
export const PhoneNumberRegex = /^(?:\+?\d{2}[ -]?[\d -][\d -]+)$/;
export const OneToHundredRegex = /^[1-9][0-9]?$|^100$/;
export const PhoneNumberAWSRegex = /^(?:\+\d+)$/;

export const AllowedFileFormats =
  ".doc, .docx, .xls, .xlsx, .csv, .pdf, .png, .jpeg, .jpg, .bmp, .tiff, .tif, .jfif, .msg";
export const AllowedImageFormats =
  ".png, .jpeg, .jpg, .bmp, .tiff, .tif, .jfif";

export const RouteNames = {
  Dashboard: "/",
  ListArtWork: "/artwork-list",
  AddArtWork: "/artwork-add",
  ViewArtwork: {
    path: "/artwork/:id/:activetab",
    link: "/artwork",
  },
  EditArtwork: {
    path: "/artwork-edit/:id",
    link: "/artwork-edit",
  },
  AddArtworkTransaction: {
    path: "/add-artwork-transaction/:id",
    link: "/add-artwork-transaction",
  },
  EditArtworkTransaction: {
    path: "/edit-artwork-transaction/:id/transaction/:transactionid",
    link: "/edit-artwork-transaction",
  },
  ArtworkTransactionSummary: {
    path: "/artwork/:id/transaction/:transactionid",
    link: "/artwork",
  },
  AddArtworkValuation: {
    path: "/add-artwork-valuation/:id",
    link: "/add-artwork-valuation",
  },
  EditArtworkValuation: {
    path: "/edit-artwork-valuation/:id/valuation/:valuationid",
    link: "/edit-artwork-valuation",
  },
  AddArtworkLoan: {
    path: "/add-artwork-loan/:id",
    link: "/add-artwork-loan",
  },
  EditArtworkLoan: {
    path: "/edit-artwork-loan/:id/loan/:loanid",
    link: "/edit-artwork-loan",
  },
  AddArtworkMovement: {
    path: "/add-artwork-movement/:id",
    link: "/add-artwork-movement",
  },
  ArtworkValuationSummary: {
    path: "/artwork/:id/valuation/:valuationid",
    link: "/artwork",
  },
  ContactsList: "/contacts-list",
  AddContact: "/contact-add",
  EditContact: {
    path: "/contact-edit/:id",
    link: "/contact-edit",
  },
  LocationsList: "/locations-list",
  AddLocation: "/location-add",
  EditLocation: {
    path: "/location-edit/:id",
    link: "/location-edit",
  },
  MovementsList: "/movements-list",

  AddMovement: "/movement-add",
  EditMovement: {
    path: "/movement-edit/:id",
    link: "/movement-edit",
  },
  UsersList: "/settings/users",
  UserDetail: {
    path: "/settings/user-details/:id",
    link: "/settings/user-details",
  },
  AddBulkArtworkIncomeExpense: {
    path: "/add-bulk-artwork-income-expense",
    link: "/add-bulk-artwork-income-expense",
  },
  AddArtworkIncomeExpense: {
    path: "/add-artwork-income-expense/:id",
    link: "/add-artwork-income-expense",
  },
  EditArtworkIncomeExpense: {
    path: "/edit-artwork-income-expense/:id/income-expense/:incomeexpenseid",
    link: "/edit-artwork-income-expense",
  },
  AddArtworkMention: {
    path: "/add-artwork-mention/:id",
    link: "/add-artwork-mention",
  },
  EditArtworkMention: {
    path: "/edit-artwork-mention/:id/mention/:mentionid",
    link: "/edit-artwork-mention",
  },
  GenerateArtworkReport: "/artwork/report",

  GroupList: "/settings/groups",
  AddGroup: "/settings/group-add",
  EditGroup: {
    path: "/settings/group-edit/:id",
    link: "/settings/group-edit",
  },
  InsurancePolicyList: "/insurance-policy",
  AddInsurancePolicy: "/insurance-policy-add",
  EditInsurancePolicy: {
    path: "/insurance-policy-edit/:id",
    link: "/insurance-policy-edit",
  },
  InsurancePolicyDetail: {
    path: "/insurance-policy-details/:id",
    link: "/insurance-policy-details",
  },
  AddInsurancePremium: {
    path: "/add-insurance-premium/:insurancepolicyid",
    link: "/add-insurance-premium",
  },
  EditInsurancePremium: {
    path: "/edit-insurance-premium/:id",
    link: "/edit-insurance-premium",
  },
  AddPremiumExpense: {
    path: "/add-insurance-premium-expense/:insurancepolicyid",
    link: "/add-insurance-premium-expense",
  },
  EditPremiumExpense: {
    path: "/edit-insurance-premium-expense/:insurancepolicyid/expense/:expenseid",
    link: "/edit-insurance-premium-expense",
  },
  AddArtworkInsurance: {
    path: "/add-artwork-insurance/:id",
    link: "/add-artwork-insurance",
  },
  EditArtworkInsurance: {
    path: "/edit-artwork-insurance/:id/insurance/:insuranceid",
    link: "/edit-artwork-insurance",
  },
  ArtworkInsuranceDetail: {
    path: "/artwork/:id/insurance-details/:insuranceId",
    link: "/artwork",
  },
  LiborList: "/settings/libor",
  LiborDetail: {
    path: "/settings/libor/:liborid",
    link: "/settings/libor",
  },
  AddMarketPlaceArtWork: "/marketplace-artwork-add",
  EditMarketPlaceArtWork: {
    path: "/marketplace-artwork-edit/:id",
    link: "/marketplace-artwork-edit",
  },
  ListMarketplace: "/list-marketplace",
  ViewMarketplace: {
    path: "/marketplace/:id/:activetab",
    link: "/marketplace",
  },
  IptList: "/settings/ipt",
  IptDetail: {
    path: "/settings/ipt/:iptid",
    link: "/settings/ipt",
  },
  GlobalSearch: "/global-search",
  ImportExpenseIncome: "/bulk-import/expense-income",
};

export const AppStorageNames = {
  UserInfo: "userInfo",
  AuthInfo: "authInfo",
};

export const DateFormates = {
  HOUR_MINUTE_DAY_MONTH_YEAR: {
    type: "HOUR_MINUTE_DAY_MONTH_YEAR",
    value: "HH:mm, DD/MM/YYYY",
  },
  DAY_MONTH_YEAR: { type: "DAY_MONTH_YEAR", value: "DD/MM/YYYY" },
  MONTH_YEAR: { type: "MONTH_YEAR", value: "MM/YYYY" },
  YEAR: { type: "YEAR", value: "YYYY" },
  CENTURY: { type: "CENTURY", value: "YYYY" },
  CIRCA: "CIRCA",
  DAYMONTHYEAR: { type: "DAY-MONTH-YEAR", value: "DD-MM-YYYY" },
};

export const DateType = {
  ExactDate: "EXACT_DATE",
  DateRange: "DATE_RANGE",
};

export const UserConstants = {
  STATUS_CONFIRMED: "CONFIRMED",
  STATUS_FORCE_CHANGE_PASSWORD: "FORCE_CHANGE_PASSWORD",
  DISABLED: "DISABLED",
};

export const UserRoles = {
  COLLECTOR: "Collector",
  COLLECTION_MANAGER: "Collection Manager",
  ADMINISTRATOR: "Administrator",
  AllRoles: ["Collector", "Collection Manager"],
};

export const IncomeExpenseConstants = {
  INCOME: "income",
  EXPENSE: "expense",
  ALL: "all",
};

export const UrlConstants = {
  PAGE: "page",
  ROW_PER_PAGE: "rowPerPage",
  ROLE: "role",
  SORT_BY: "sortBy",
  SEARCH_TEXT: "searchText",
  ARTWORK_FILTER: "artworkFilter",
  CONTACTS: "contacts",
  TOTALCONTACTNUM: "totalNumOfContacts",
  SMALL_THUMBNAIL: "small",
  MEDIUM_THUMBNAIL: "medium"
};

export const AppEnv = {
  PROD: "prod"
};


export const preferredOrder = [
  {
    "code": "USD",
    "name": "Dollar",
    "updatedAt": "2022-07-26T10:12:04.606+0000",
    "lastUpdatedBy": "10348f57-e8e0-4b16-a5c5-e51218b21e37"
  },
  {
    "code": "GBP",
    "name": "the British pound sterling",
    "updatedAt": "2022-07-26T10:12:04.606+0000",
    "lastUpdatedBy": "10348f57-e8e0-4b16-a5c5-e51218b21e37"
  },
  {
    "code": "EUR",
    "name": "Euro",
    "updatedAt": "2022-07-26T10:12:04.606+0000",
    "lastUpdatedBy": "10348f57-e8e0-4b16-a5c5-e51218b21e37"
  },
  {
    "code": "CHF",
    "name": "the Swiss franc",
    "updatedAt": "2022-07-26T10:12:04.606+0000",
    "lastUpdatedBy": "10348f57-e8e0-4b16-a5c5-e51218b21e37"
  },
  {
    "code": "ILS",
    "name": "the Israeli new shekel",
    "updatedAt": "2022-07-26T10:12:04.606+0000",
    "lastUpdatedBy": "10348f57-e8e0-4b16-a5c5-e51218b21e37"
  }
];