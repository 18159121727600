import { ActionTypes } from "../ActionTypes";

const { MOVEMENTS } = ActionTypes;

const INITIAL_STATE = {
    movement: {},
    paginatedMovements: {},
    movementDetailId: null
};

const MovementReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case MOVEMENTS.SET_MOVEMENT_LIST:
            return {
                ...state,
                paginatedMovements: payload
            };
        case MOVEMENTS.SET_MOVEMENT_DETAIL_ID:
            return {
                ...state,
                movementDetailId: payload,
            };
        case MOVEMENTS.SET_MOVEMENT:
            return {
                ...state,
                movement: payload
            };
        default:
            return state;
    }
}

export default MovementReducer;
