import { isEmpty } from "lodash";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { selectCurrentUser } from "../redux/user/user.selector";
import { UserRoles } from "../utils";
import routesList from "./routes-list";
import AppConfig from "../config/AppConfig";
import { AppEnv } from "../utils/Constants";

const { APP_ENV } = AppConfig;

const Routes = () => {
  const currentUser = useSelector(selectCurrentUser, shallowEqual);
  const history = useHistory();
  const validateRoute = (
    SuccessComponent,
    roles,
    invalidRoute,
    activeTabKeyValue = null
  ) => {
    if (roles.includes(currentUser.role)) {
      if (isEmpty(activeTabKeyValue)) {
        return <SuccessComponent />;
      } else {
        return <SuccessComponent activeTabKey={activeTabKeyValue} />;
      }
    }
    history.push(invalidRoute);
  };

  return (
    <Switch>
      {currentUser &&
        (currentUser?.role === UserRoles.COLLECTION_MANAGER ||
          currentUser?.role === UserRoles.COLLECTOR) && (
          <>
            {/* hide dashboard for production environment */}
            <Route
              exact
              path={routesList.Dashboard.path}
              render={() =>
                AppEnv.PROD === APP_ENV ? (
                  <Redirect from="*" to={routesList.ListArtWork.path} />
                ) : (
                  <routesList.Dashboard.component />
                )
              }
            />
            <Route
              exact
              path={routesList.ListArtWork.path}
              render={() => <routesList.ListArtWork.component />}
            />
            <Route
              exact
              path={routesList.AddArtWork.path}
              render={() => <routesList.AddArtWork.component />}
            />
            <Route
              exact
              path={routesList.ViewArtwork.path}
              render={() => <routesList.ViewArtwork.component />}
            />
            <Route
              exact
              path={routesList.EditArtwork.path}
              render={() => <routesList.EditArtwork.component />}
            />
            <Route
              exact
              path={routesList.AddArtworkTransaction.path}
              render={() => <routesList.AddArtworkTransaction.component />}
            />
            <Route
              exact
              path={routesList.EditArtworkTransaction.path}
              render={() => <routesList.EditArtworkTransaction.component />}
            />
            <Route
              exact
              path={routesList.ArtworkTransactionSummary.path}
              render={() => <routesList.ArtworkTransactionSummary.component />}
            />
            <Route
              exact
              path={routesList.ArtworkValuationSummary.path}
              render={() => (
                <routesList.ArtworkValuationSummary.component
                  activeTabKey={"3"}
                />
              )}
            />
            <Route
              exact
              path={routesList.AddArtworkValuation.path}
              render={() => <routesList.AddArtworkValuation.component />}
            />
            <Route
              exact
              path={routesList.EditArtworkValuation.path}
              render={() => <routesList.EditArtworkValuation.component />}
            />
            <Route
              exact
              path={routesList.AddArtworkLoan.path}
              render={() => <routesList.AddArtworkLoan.component />}
            />
            <Route
              exact
              path={routesList.EditArtworkLoan.path}
              render={() => <routesList.EditArtworkLoan.component />}
            />
            <Route
              exact
              path={routesList.ContactsList.path}
              render={() => <routesList.ContactsList.component />}
            />
            <Route
              exact
              path={routesList.AddContact.path}
              render={() => <routesList.AddContact.component />}
            />
            <Route
              exact
              path={routesList.EditContact.path}
              render={() => <routesList.EditContact.component />}
            />
            <Route
              exact
              path={routesList.LocationsList.path}
              render={() => (
                <routesList.LocationsList.component activeTabKey={"1"} />
              )}
            />
            <Route
              exact
              path={routesList.AddLocation.path}
              render={() => <routesList.AddLocation.component />}
            />
            <Route
              exact
              path={routesList.EditLocation.path}
              render={() => <routesList.EditLocation.component />}
            />
            <Route
              exact
              path={routesList.MovementsList.path}
              render={() => (
                <routesList.MovementsList.component activeTabKey={"2"} />
              )}
            />
            <Route
              exact
              path={routesList.AddMovement.path}
              render={() => <routesList.AddMovement.component />}
            />

            <Route
              exact
              path={routesList.AddArtworkMovement.path}
              render={() => <routesList.AddArtworkMovement.component />}
            />

            <Route
              exact
              path={routesList.EditMovement.path}
              render={() => <routesList.EditMovement.component />}
            />
            <Route
              exact
              path={routesList.UsersList.path}
              render={() =>
                validateRoute(
                  routesList.UsersList.component,
                  routesList.UsersList.roles,
                  "/",
                  "1"
                )
              }
            />
            <Route
              exact
              path={routesList.UserDetail.path}
              render={() =>
                validateRoute(
                  routesList.UserDetail.component,
                  routesList.UserDetail.roles,
                  "/"
                )
              }
            />
            <Route
              exact
              path={routesList.AddArtworkIncomeExpense.path}
              render={() => <routesList.AddArtworkIncomeExpense.component />}
            />
             <Route
              exact
              path={routesList.AddBulkArtworkIncomeExpense.path}
              render={() => <routesList.AddBulkArtworkIncomeExpense.component />}
            />
            <Route
              exact
              path={routesList.EditArtworkIncomeExpense.path}
              render={() => <routesList.EditArtworkIncomeExpense.component />}
            />
            <Route
              exact
              path={routesList.AddArtworkMentions.path}
              render={() => <routesList.AddArtworkMentions.component />}
            />
            <Route
              exact
              path={routesList.EditArtworkMentions.path}
              render={() => <routesList.EditArtworkMentions.component />}
            />
            <Route
              exact
              path={routesList.GroupsList.path}
              render={() =>
                validateRoute(
                  routesList.GroupsList.component,
                  routesList.GroupsList.roles,
                  "/",
                  "2"
                )
              }
            />
            <Route
              exact
              path={routesList.AddGroup.path}
              render={() =>
                validateRoute(
                  routesList.AddGroup.component,
                  routesList.AddGroup.roles,
                  "/"
                )
              }
            />
            <Route
              exact
              path={routesList.EditGroup.path}
              render={() =>
                validateRoute(
                  routesList.EditGroup.component,
                  routesList.EditGroup.roles,
                  "/"
                )
              }
            />
            <Route
              exact
              path={routesList.InsurancePolicyList.path}
              render={() =>
                validateRoute(
                  routesList.InsurancePolicyList.component,
                  routesList.InsurancePolicyList.roles,
                  "/"
                )
              }
            />
            <Route
              exact
              path={routesList.AddInsurancePolicy.path}
              render={() =>
                validateRoute(
                  routesList.AddInsurancePolicy.component,
                  routesList.AddInsurancePolicy.roles,
                  "/"
                )
              }
            />
            <Route
              exact
              path={routesList.EditInsurancePolicy.path}
              render={() =>
                validateRoute(
                  routesList.EditInsurancePolicy.component,
                  routesList.EditInsurancePolicy.roles,
                  "/"
                )
              }
            />
            <Route
              exact
              path={routesList.InsurancePolicyDetail.path}
              render={() =>
                validateRoute(
                  routesList.InsurancePolicyDetail.component,
                  routesList.InsurancePolicyDetail.roles,
                  "/"
                )
              }
            />
            <Route
              exact
              path={routesList.AddArtworkInsurance.path}
              render={() => <routesList.AddArtworkInsurance.component />}
            />
            <Route
              exact
              path={routesList.EditArtworkInsurance.path}
              render={() => <routesList.EditArtworkInsurance.component />}
            />
            <Route
              exact
              path={routesList.ArtworkInsuranceDetail.path}
              render={() => <routesList.ArtworkInsuranceDetail.component />}
            />
            <Route
              exact
              path={routesList.LiborList.path}
              render={() => (
                <routesList.LiborList.component activeTabKey={"3"} />
              )}
            />
            <Route
              exact
              path={routesList.LiborDetail.path}
              render={() =>
                validateRoute(
                  routesList.LiborDetail.component,
                  routesList.LiborDetail.roles,
                  "/",
                  "3"
                )
              }
            />
            <Route
              exact
              path={routesList.AddMarketPlaceArtWork.path}
              render={() => <routesList.AddMarketPlaceArtWork.component />}
            />
            <Route
              exact
              path={routesList.EditMarketPlaceArtWork.path}
              render={() => <routesList.EditMarketPlaceArtWork.component />}
            />
            <Route
              exact
              path={routesList.ListMarketplace.path}
              render={() => <routesList.ListMarketplace.component />}
            />
            <Route
              exact
              path={routesList.ViewMarketplace.path}
              render={() => <routesList.ViewMarketplace.component />}
            />
            <Route
              exact
              path={routesList.IptList.path}
              render={() =>
                validateRoute(
                  routesList.IptList.component,
                  routesList.IptList.roles,
                  "/",
                  "4"
                )
              }
            />
            <Route
              exact
              path={routesList.IptDetail.path}
              render={() =>
                validateRoute(
                  routesList.IptDetail.component,
                  routesList.IptDetail.roles,
                  "/",
                  "4"
                )
              }
            />
            <Route
              exact
              path={routesList.AddInsurancePremium.path}
              render={() =>
                validateRoute(
                  routesList.AddInsurancePremium.component,
                  routesList.AddInsurancePremium.roles,
                  "/"
                )
              }
            />
            <Route
              exact
              path={routesList.AddPremiumExpense.path}
              render={() =>
                validateRoute(
                  routesList.AddPremiumExpense.component,
                  routesList.AddPremiumExpense.roles,
                  "/"
                )
              }
            />
            <Route
              exact
              path={routesList.GenerateArtworkReport.path}
              render={() => <routesList.GenerateArtworkReport.component />}
            />
            <Route
              exact
              path={routesList.EditPremiumExpense.path}
              render={() =>
                validateRoute(
                  routesList.EditPremiumExpense.component,
                  routesList.EditPremiumExpense.roles,
                  "/"
                )
              }
            />
            <Route
              exact
              path={routesList.GlobalSearch.path}
              render={() => <routesList.GlobalSearch.component />}
            />
            <Route
              exact
              path={routesList.ImportExpenseIncome.path}
              render={() =>
                validateRoute(
                  routesList.ImportExpenseIncome.component,
                  routesList.ImportExpenseIncome.roles,
                  "/"
                )
              }
            />
          </>
        )}
        {/* TODO: redirect other routes to home */}
      <Redirect from="*" to="/" />
    </Switch>
  );
};

export default Routes;
