import { setIsLiborRateExpired } from "../../../redux/libor-rate/libor-rate.action";
import { isLiborRateExpired } from "../../../services/api/liborService";

class LiborRateHelper {
  static checkLiborRate = async (dispatch) => {
    try {
      const isExpired = await isLiborRateExpired();
      dispatch(setIsLiborRateExpired(isExpired));
    } catch (error) {
      dispatch(setIsLiborRateExpired(false));
    }
  };
}

export default LiborRateHelper;
