import { isEmpty } from "lodash";
import AppConfig from "../config/AppConfig";
import WebStorage from "./WebStorage";
import jwtDecode from "jwt-decode";
import storage from "redux-persist/lib/storage";

const { COGNITO } = AppConfig;

export const ACCESS_TOKEN = "access_token";
const ID_TOKEN = "id_token";
const USER_INFO = "user_info";
const USERNAME = "username";
const REFRESH_TOKEN = "refresh_token";

const SCOPE = "openid";
export const loginUrl = `${COGNITO.UserPoolBaseUri}/oauth2/authorize?scope=${SCOPE}&response_type=code&client_id=${COGNITO.ClientId}&redirect_uri=${COGNITO.CallbackUri}`;
export const logoutUrl = `${COGNITO.UserPoolBaseUri}/logout?client_id=${COGNITO.ClientId}&logout_uri=${COGNITO.SignoutUri}`;

export const getToken = () => {
  const accessToken = WebStorage.getLocalStore(ACCESS_TOKEN);
  if (!isEmpty(accessToken)) {
    return accessToken;
  }
  return null;
};

export const getUserInfo = () => {
  const userInfo = WebStorage.getLocalStore(USER_INFO);
  return isEmpty(userInfo) ? {} : userInfo;
};

export const getUsername = () => {
  const username = WebStorage.getLocalStore(USERNAME);
  return isEmpty(username) ? "" : username;
};

export const getRefreshToken = () => {
  const refreshToken = WebStorage.getLocalStore(REFRESH_TOKEN);
  if (!isEmpty(refreshToken)) {
    return refreshToken;
  }
  return null;
};

export const setTokens = (access_token, id_token, user, refresh_token) => {
  WebStorage.setLocalStore(ACCESS_TOKEN, access_token);
  WebStorage.setLocalStore(ID_TOKEN, id_token);
  if (refresh_token) WebStorage.setLocalStore(REFRESH_TOKEN, refresh_token);
  if (user?.username) {
    WebStorage.setLocalStore(USERNAME, user?.username);
  }
};

export const removeTokens = () => {
  WebStorage.removeLocalStore(ACCESS_TOKEN);
  WebStorage.removeLocalStore(ID_TOKEN);
  WebStorage.removeLocalStore(USER_INFO);
  WebStorage.removeLocalStore(USERNAME);
  WebStorage.removeLocalStore(REFRESH_TOKEN);
  storage.removeItem("persist:root");
};

export const isTokenExpired = (token) => {
  const decodedValue = jwtDecode(token);
  if (Date.now() >= decodedValue.exp * 1000) {
    return true;
  }
  return false;
};
