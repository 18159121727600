import { ActionTypes } from "../ActionTypes";

const { COUNTRY_IPT_RATE } = ActionTypes;

const INITIAL_STATE = {
  iptRates: { data: [], count: 0 },
};

const CountryIptRateReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case COUNTRY_IPT_RATE.SET_COUNTRY_IPT_RATE_LIST:
      return {
        ...state,
        iptRates: payload,
      };
    default:
      return state;
  }
};

export default CountryIptRateReducer;
