import { createSelector } from "reselect";

const selectUser = (state) => state.userReducer;

export const selectCurrentUser = createSelector(
  [selectUser],
  (user) => user?.currentUser
);

export const selectAllUsers = createSelector(
  [selectUser],
  (user) => user.paginatedUsers || {} 
);

export const selectAllUsersCount = createSelector(
  [selectUser],
  (user) => user.paginatedUsers?.count
);

export const selectAllRoles = createSelector(
  [selectUser],
  (user) => user.rolesList
);

export const selectAllGroups = createSelector(
  [selectUser],
  (user) => user.groupsList
);

export const selectUserData = createSelector([selectUser], (user) => user.user);
