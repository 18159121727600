import ActionTypes from "../ActionTypes";
const { USER_ACTIONS } = ActionTypes;

export const setCurrentUser = (user) => ({
  type: USER_ACTIONS.SET_CURRENT_USER_SESSION,
  payload: user,
});

export const setPaginatedUsers = (paginatedUsers) => ({
  type: USER_ACTIONS.SET_USERS_LISTS,
  payload: paginatedUsers,
});

export const setRoles = (roles) => ({
  type: USER_ACTIONS.SET_ROLES_LISTS,
  payload: roles,
});

export const setGroups = (groups) => ({
  type: USER_ACTIONS.SET_GROUPS_LISTS,
  payload: groups,
});

export const setUser = (user) => ({
  type: USER_ACTIONS.SET_USER,
  payload: user,
});
