import { ActionTypes } from "../ActionTypes";

const { INSURANCE_POLICIES } = ActionTypes;

const INITIAL_STATE = {
  insurancePolicy: {},
  insurancePolicies: {},
  insurancePolicyDetailId: null,
  premiumExpenseList: [],
};

const InsurancePolicyReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case INSURANCE_POLICIES.SET_INSURANCE_POLICY_LIST:
      return {
        ...state,
        insurancePolicies: payload,
      };
    case INSURANCE_POLICIES.SET_INSURANCE_POLICY_DETAIL_ID:
      return {
        ...state,
        insurancePolicyDetailId: payload,
      };
    case INSURANCE_POLICIES.SET_INSURANCE_POLICY:
      return {
        ...state,
        insurancePolicy: payload,
      };
    case INSURANCE_POLICIES.ADD_PREMIUM_EXPENSE:
      return {
        ...state,
        premiumExpenseList: payload,
      };
    default:
      return state;
  }
};

export default InsurancePolicyReducer;
