import { isEmpty } from "lodash";
import WebStorage from "../../lib/WebStorage";
import request from "../request";
import { artworkAPI, serverSideEventsAPI, statusAPI } from "./urls";
import AppConfig from "../../config/AppConfig";
const { BASE_URL } = AppConfig;
export const populateAddArtworkDropdown = (
  isArtist = false,
  forCollection = false
) => {
  return request({
    url: artworkAPI.populateAddArtworkDropdown(isArtist, forCollection),
    method: "GET",
  })
    .then((response) => response)
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const loadArtWorkCategories = () => {
  return request({
    url: artworkAPI.getArtWorkCargories(), 
    method: "GET",
  })
    .then((response) => response)
    .catch((error) => {
      return Promise.reject(error.response);
    })
}

export const loadArtWorkTags = () => {
  return request({
    url: artworkAPI.getArtWorkTags(),
    method: "GET",
  })
    .then(response => response)
    .catch(error => {
      return Promise.reject(error.response);
    })
}

export const loadArtWorkLocations = () => {
  return request({
    url: artworkAPI.getArtWorkLocations(),
    method: "GET",
  })
    .then(response => response)
    .catch(error => {
      return Promise.reject(error.response);
    })
}

export const loadArtWorkContacts = () => {
  return request({
    url: artworkAPI.getArtWorkContacts(),
    method: "GET",
  })
    .then(response => response)
    .catch(error => {
      return Promise.reject(error.response);
    })
}

export const loadArtWorkGroups = () => {
  return request({
    url: artworkAPI.getArtWorkGroups(),
    method: "GET",
  })
    .then(response => response)
    .catch(error => {
      return Promise.reject(error.response);
    })
}

export const loadArtWorkOwners = () => {
  return request({
    url: artworkAPI.getArtWorkOwners(),
    method: "GET",
  })
    .then(response => response)
    .catch(error => {
      return Promise.reject(error.response);
    })
}

export const loadArtWorkArtists = () => {
  return request({
    url: artworkAPI.getArtWorkArtists(),
    method: "GET",
  })
    .then(response => response)
    .catch(error => {
      return Promise.reject(error.response);
    })
}

export const loadArtWorkStatuses = () => {
  return request({
    url: artworkAPI.getArtWorkStatuses(),
    method: "GET",
  })
    .then(response => response)
    .catch(error => {
      return Promise.reject(error.response);
    })
}

export const loadArtWorksMediums = () => {
  return request({
    url: artworkAPI.getArtWorkMediums(),
    method: "GET",
  })
    .then(response => response)
    .catch(error => {
      return Promise.reject(error.response);
    })
}

export const loadArtWorkCurrencies = () => {
  return request({
    url: artworkAPI.getArtWorkCurrencies(),
    method: "GET",
  })
    .then(response => response)
    .catch(error => {
      return Promise.reject(error.response);
    })
}

export const loadArtWorkTransactionTypes = () => {
  return request({
    url: artworkAPI.getArtWorkTransactionTypes(),
    method: "GET",
  })
    .then(response => response)
    .catch(error => {
      return Promise.reject(error.response);
    })
}

export const loadArtWorkCollectionTags = () => {
  return request({
    url: `${BASE_URL}artworks/template/collectionTags`,
    method: "GET",
  })
    .then(response => response)
    .catch(error => {
      return Promise.reject(error.response);
    })
}

export const validateArtworkCardnumber = (number, artworkId) => {
  return request({
    url: artworkAPI.validateCardNumber(number, artworkId),
    method: "GET",
  })
    .then((response) => response)
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const addNewArtwork = (data) => {
  return request({
    url: artworkAPI.addArtwork,
    method: "POST",
    data: data,
  })
    .then((response) => response)
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const isPageTop = (searchFilters) => {
  if (!isEmpty(searchFilters.owner)) {
    return true
  }else{
    return false
  }
}

export const getArtworks = (
  pageNumber,
  perPage,
  searchFilters,
  cancelToken
) => {

  let url = artworkAPI.viewArtworks(pageNumber, perPage);
  // console.log('URL : ', searchFilters);
  if (searchFilters) {
    if (searchFilters.minInsuranceVal === 0 || searchFilters.minInsuranceVal > 0) {
      url = `${url}&minInsuranceVal=${searchFilters.minInsuranceVal}`;
    }

    if (searchFilters.maxInsuranceVal !== '' && searchFilters.maxInsuranceVal >= 0) {
      url = `${url}&maxInsuranceVal=${searchFilters.maxInsuranceVal}`;
    }
    if (!isEmpty(searchFilters.group)) {
      url = `${url}&groupList=${searchFilters.group}`;
    }
    if (!isEmpty(searchFilters.category)) {
      url = `${url}&category=${searchFilters.category}`;
    }
    if (!isEmpty(searchFilters.tags)) {
      url = `${url}&tagList=${searchFilters.tags.join(",")}`;
    }
    if (!isEmpty(searchFilters.fromDate)) {
      url = `${url}&fromDate=${searchFilters.fromDate}`;
    }
    if (!isEmpty(searchFilters.toDate)) {
      url = `${url}&toDate=${searchFilters.toDate}`;
    }
    if (!isEmpty(searchFilters.location)) {
      url = `${url}&currentLocation=${searchFilters.location}`;
    }
    if (!isEmpty(searchFilters.locationContact)) {
      url = `${url}&currentContact=${searchFilters.locationContact}`;
    }
    if (!isEmpty(searchFilters.height)) {
      url = `${url}&height=${searchFilters.height.join(",")}`;
    }
    if (!isEmpty(searchFilters.width)) {
      url = `${url}&width=${searchFilters.width.join(",")}`;
    }
    if (!isEmpty(searchFilters.depth)) {
      url = `${url}&depth=${searchFilters.depth.join(",")}`;
    }
    if (!isEmpty(searchFilters.sortBy)) {
      url = `${url}&sortBy=${searchFilters.sortBy}`;
    }
    if (!isEmpty(searchFilters.owner)) {
      url = `${url}&ownerList=${searchFilters.owner.join(",")}`;
    }
    if (!isEmpty(searchFilters.artist)) {
      url = `${url}&artistList=${searchFilters.artist}`;
    }
    if (!isEmpty(searchFilters.status)) {
      url = `${url}&statusList=${searchFilters.status}`;
    }
    if (!isEmpty(searchFilters.locationFromDate)) {
      url = `${url}&locationFromDate=${searchFilters.locationFromDate}`;
    }
    if (!isEmpty(searchFilters.locationToDate)) {
      url = `${url}&locationToDate=${searchFilters.locationToDate}`;
    }
  }

  return request({
    url,
    method: "GET",
    cancelToken: cancelToken,
  })
    .then((response) => response)
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const getAttachmentByIdAndEntity = (
  imageRefId,
  cancelToken,
  entityName = "Artworks",
  thumbnail = "",
  mainImage,
) => {
  return request(
    {
      url: artworkAPI.getAttachmentByIdAndEntity(
        imageRefId,
        entityName,
        thumbnail, 
        mainImage,
      ),
      method: "GET",
      cancelToken: cancelToken,
    },
    900000
  )
    .then((response) => response)
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const getBlobAttachmentByIdAndEntity = (
  imageRefId,
  cancelToken,
  entityName = "Artworks"
) => {
  return request(
    {
      url: artworkAPI.getAttachmentByIdAndEntity(imageRefId, entityName),
      method: "GET",
      responseType: "blob",
      cancelToken: cancelToken,
    },
    900000
  )
    .then((response) => response)
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const getArtwork = (id, onlyAttachmentsList) => {
  return request(
    {
      url: artworkAPI.viewArtwork(id, onlyAttachmentsList),
      method: "GET",
    },
    onlyAttachmentsList === false ? 900000 : 300000
  )
    .then((response) => response)
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const getArtworkStatusDate = (artworkId, cancelToken) => {
  return request({
    url: artworkAPI.getLastUpdateStatusDate(artworkId),
    method: "GET",
    cancelToken: cancelToken,
  })
    .then((response) => response)
    .catch((error) => Promise.reject(error.response));
};

export const getArtworkAllInCost = (id, cancelToken) => {
  return request({
    url: artworkAPI.viewArtworkAllInCost(id),
    method: "GET",
    cancelToken: cancelToken,
  })
    .then((response) => response)
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const editArtwork = (id, data) => {
  return request({
    url: artworkAPI.editArtwork(id),
    method: "PUT",
    data: data,
  })
    .then((response) => response)
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const uploadFiles = (formData, artwork_id) => {
  return request(
    {
      url: artworkAPI.uploadFiles(artwork_id),
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    },
    900000
  )
    .then((response) => response)
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const uploadUpdatedFiles = (formData, artwork_id) => {
  return request(
    {
      url: artworkAPI.uploadFiles(artwork_id),
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    },
    900000
  )
    .then((response) => response)
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const populateChangeStatusDropdown = (cancelToken) => {
  return request({
    url: statusAPI.statusTemplate,
    method: "GET",
    cancelToken: cancelToken,
  })
    .then((response) => response)
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const updateArtworkStatus = (artworkId, status) => {
  return request({
    url: artworkAPI.updateArtworkStatus(artworkId),
    method: "PATCH",
    data: status,
  })
    .then((response) => response)
    .catch((error) => Promise.reject(error.response));
};

export const updateArtworkInsurancePrimary = (artworkId, insuranceId) => {
  return request({
    url: artworkAPI.updateArtworkInsurancePrimary(artworkId, insuranceId),
    method: "PATCH",
    data: {},
  })
    .then((response) => response)
    .catch((error) => Promise.reject(error.response));
};

export const getMarketplaceArtworks = (
  pageNumber,
  perPage,
  searchFilters,
  cancelToken
) => {
  let url = artworkAPI.viewMarketplaceArtworks(pageNumber, perPage);
  if (searchFilters) {
    if (!isEmpty(searchFilters.category)) {
      url = `${url}&category=${searchFilters.category}`;
    }
    if (!isEmpty(searchFilters.tags)) {
      url = `${url}&tagList=${searchFilters.tags.join(",")}`;
    }
    if (!isEmpty(searchFilters.fromDate)) {
      url = `${url}&fromDate=${searchFilters.fromDate}`;
    }
    if (!isEmpty(searchFilters.toDate)) {
      url = `${url}&toDate=${searchFilters.toDate}`;
    }
    if (!isEmpty(searchFilters.height)) {
      url = `${url}&height=${searchFilters.height.join(",")}`;
    }
    if (!isEmpty(searchFilters.width)) {
      url = `${url}&width=${searchFilters.width.join(",")}`;
    }
    if (!isEmpty(searchFilters.depth)) {
      url = `${url}&depth=${searchFilters.depth.join(",")}`;
    }
    if (!isEmpty(searchFilters.sortBy)) {
      url = `${url}&sortBy=${searchFilters.sortBy}`;
    }
    if (!isEmpty(searchFilters.artist)) {
      url = `${url}&artistList=${searchFilters.artist}`;
    }
    if (searchFilters.collectionArtwork) {
      url = `${url}&collectionArtwork=${searchFilters.collectionArtwork}`;
    }
    if (searchFilters.marketplaceArtwork) {
      url = `${url}&marketplaceArtwork=${searchFilters.marketplaceArtwork}`;
    }
  }
  return request({
    url,
    method: "GET",
    cancelToken: cancelToken,
  })
    .then((response) => response)
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const getMarketplaceArtworkById = (id) => {
  return request({
    url: artworkAPI.viewMarketplaceArtwork(id),
    method: "GET",
  })
    .then((response) => response)
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const addMarketArtworkToCollection = (artworkId, data) => {
  return request({
    url: artworkAPI.addToInCollection(artworkId),
    method: "PATCH",
    data: data,
  })
    .then((response) => response)
    .catch((error) => Promise.reject(error.response));
};

export const deleteMarketplaceByID = (id, cancelToken) => {
  return request({
    url: artworkAPI.viewArtwork(id),
    method: "DELETE",
    cancelToken: cancelToken,
  })
    .then((response) => response)
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const bulkUploadExpenseIncome = (formData) => {
  return request({
    url: serverSideEventsAPI.local,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const importExpenseIncome = (data, guidValue, cancelToken) => {
  return request({
    url: serverSideEventsAPI.importIncomeExpense(guidValue),
    method: "PUT",
    data: data,
    cancelToken,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const getArtworkStatusDateHistory = (artworkId, cancelToken) => {
  return request({
    url: artworkAPI.getStatusChangeDates(artworkId),
    method: "GET",
    cancelToken: cancelToken,
  })
    .then((response) => response)
    .catch((error) => Promise.reject(error.response));
};

const artworkService = {
  populateAddArtworkDropdown,
  validateArtworkCardnumber,
  addNewArtwork,
  getArtworks,
  getArtwork,
  getAttachmentByIdAndEntity,
  getBlobAttachmentByIdAndEntity,
  editArtwork,
  uploadFiles,
  uploadUpdatedFiles,
  populateChangeStatusDropdown,
  updateArtworkStatus,
  updateArtworkInsurancePrimary,
  getMarketplaceArtworks,
  getMarketplaceArtworkById,
  deleteMarketplaceByID,
  bulkUploadExpenseIncome,
  importExpenseIncome,
  getArtworkStatusDateHistory,
};

export default artworkService;
