import { ActionTypes } from "../ActionTypes";

const { MARKETPLACE } = ActionTypes;

const INITIAL_STATE = {
  paginatedArtWorks: {},
  artwork: {},
};

const MarketplaceReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case MARKETPLACE.SET_MARKETPLACE_LIST:
      return {
        ...state,
        paginatedArtWorks: payload,
      };
    case MARKETPLACE.SET_MARKETPLACE_ARTWORK:
      return {
        ...state,
        artwork: payload,
      };
      case MARKETPLACE.SET_ARTWORK_INCOLLECTION:
        return {
          ...state,
          artwork : {...state.artwork, inCollection : payload}
        };
    default:
      return state;
  }
};

export default MarketplaceReducer;
