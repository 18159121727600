const { default: ActionTypes } = require("../ActionTypes");

const { GLOBAL_SEARCH } = ActionTypes;

const INITIAL_STATE = {
  searchText: "",
  searchResp: {},
  contacts: [],
  totalContactNumber: 0,
};

const SearchReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case GLOBAL_SEARCH.SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: payload,
      };
      case GLOBAL_SEARCH.SET_TOTAL_CONTACT_NUMBER:
        return {
          ...state,
          totalContactNumber: payload,
        };
      case GLOBAL_SEARCH.SET_SELECTED_CONTACTS:
        return {
          ...state,
          contacts: payload,
        };
    case GLOBAL_SEARCH.SET_GLOBAL_SEARCH_LIST:
      return {
        ...state,
        searchResp: payload,
      };
    default:
      return state;
  }
};

export default SearchReducer;
