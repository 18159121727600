import { ActionTypes } from "../ActionTypes";

const { CONTACTS } = ActionTypes;

const INITIAL_STATE = {
  paginatedContacts: {},
  contactDetailId: null,
  contact: {}
};

const ContactReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case CONTACTS.SET_CONTACT_LIST:
      return {
        ...state,
        paginatedContacts: payload,
      };
    case CONTACTS.SET_CONTACT_DETAIL_ID:
      return {
        ...state,
        contactDetailId: payload,
      };
    case CONTACTS.SET_CONTACT:
      return {
        ...state,
        contact: payload
      };
    default:
      return state;
  }
};

export default ContactReducer;
