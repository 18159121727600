import { ActionTypes } from "../ActionTypes";

const { ARTWORKS } = ActionTypes;

const INITIAL_STATE = {
  artwork: {},
  refreshArtwork: null,
  allInCost: "",
  refreshAIC: null,
  artworkStatusChangeHistory: [],
  paginatedArtWorks: {},
  artworkTransactions: [],
  newlyAddedArtwork: {
    id: null,
  },
  artworkValuations: [],
  valuationDetailId: null,
  valuation: {},
  artworkIncomeExpense: [],
  incomeExpenseDetailId: null,
  incomeExpense: {},
  artworkLoan: [],
  loanDetailId: null,
  loan: {},
  artworkExhibitionPublication: [],
  exhibitionPublicationDetailId: null,
  exhibitionPublication: {},
  artworkAttachment: [],
};

const ArtworkReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case ARTWORKS.SET_ARTWORK_LIST:
      return {
        ...state,
        paginatedArtWorks: payload,
      };
    case ARTWORKS.SET_ARTWORK:
      return {
        ...state,
        artwork: payload,
      };
    case ARTWORKS.SET_REFRESH_ARTWORK:
      return {
        ...state,
        refreshArtwork: payload,
      };
    case ARTWORKS.SET_ARTWORK_ALLINCOST:
      return {
        ...state,
        allInCost: payload,
      };
    case ARTWORKS.SET_REFRESH_ALLINCOST:
      return {
        ...state,
        refreshAIC: payload,
      };
    case ARTWORKS.ARTWORK_ADDED_SUCCESSFULLY:
      return {
        ...state,
        newlyAddedArtwork: { id: payload },
      };
    case ARTWORKS.CLEARLY_ADDED_SUCCESSFULLY:
      return {
        ...state,
        newlyAddedArtwork: null,
      };
    case ARTWORKS.SET_ARTWORK_STATUS_CHANGE_HISTORY:
      return {
        ...state,
        artworkStatusChangeHistory: payload,
      };
    case ARTWORKS.SET_ARTWORK_TRANSACTIONS_LIST:
      return {
        ...state,
        artworkTransactions: payload,
      };
    case ARTWORKS.SET_ARTWORK_VALUATIONS_LIST:
      return {
        ...state,
        artworkValuations: payload,
      };
    case ARTWORKS.SET_VALUATION_DETAIL_ID:
      return {
        ...state,
        valuationDetailId: payload,
      };
    case ARTWORKS.SET_VALUATION:
      return {
        ...state,
        valuation: payload,
      };
    case ARTWORKS.SET_ARTWORK_INCOME_EXPENSE_LIST:
      return {
        ...state,
        artworkIncomeExpense: payload,
      };
    case ARTWORKS.SET_INCOME_EXPENSE_DETAIL_ID:
      return {
        ...state,
        incomeExpenseDetailId: payload,
      };
    case ARTWORKS.SET_INCOME_EXPENSE:
      return {
        ...state,
        incomeExpense: payload,
      };

    case ARTWORKS.SET_ARTWORK_LOAN_LIST:
      return {
        ...state,
        artworkLoan: payload,
      };
    case ARTWORKS.SET_LOAN_DETAIL_ID:
      return {
        ...state,
        loanDetailId: payload,
      };
    case ARTWORKS.SET_LOAN:
      return {
        ...state,
        loan: payload,
      };

    case ARTWORKS.SET_ARTWORK_EXHIBITION_PUBLICATION_LIST:
      return {
        ...state,
        artworkExhibitionPublication: payload,
      };
    case ARTWORKS.SET_EXHIBITION_PUBLICATION_DETAIL_ID:
      return {
        ...state,
        exhibitionPublicationDetailId: payload,
      };
    case ARTWORKS.SET_EXHIBITION_PUBLICATION:
      return {
        ...state,
        exhibitionPublication: payload,
      };
    case ARTWORKS.SET_EXHIBITION_RICH_TEXT:
      return {
        ...state,
        artwork: { ...state.artwork, exhibitionText: payload },
      };

    case ARTWORKS.SET_PUBLICATION_RICH_TEXT:
      return {
        ...state,
        artwork: { ...state.artwork, publicationText: payload },
      };
    case ARTWORKS.SET_ARTWORK_ATTACHMENT_LIST:
      return {
        ...state,
        artworkAttachment: payload,
      };

    default:
      return state;
  }
};

export default ArtworkReducer;
