import request from "../request";
import { liborAPI } from "./urls";

export const getLiborRates = (pageNumber, perPage, cancelToken) => {
  let url = liborAPI.getLiborRates(pageNumber, perPage);

  return request({
    url: url,
    method: "GET",
    cancelToken: cancelToken,
  })
    .then((response) => response)
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const createLibor = (liborData, cancelToken) => {
  return request({
    url: liborAPI.createLibor,
    method: "POST",
    data: liborData,
    cancelToken: cancelToken,
  })
    .then((response) => response)
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const getLiborRate = (liborId, cancelToken) => {
  let url = liborAPI.getLiborRate(liborId);

  return request({
    url: url,
    method: "GET",
    cancelToken: cancelToken,
  })
    .then((response) => response)
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const updateLiborRate = (liborId, liborData, cancelToken) => {
  let url = liborAPI.editLibor(liborId);

  return request({
    url: url,
    method: "PUT",
    cancelToken: cancelToken,
    data: liborData,
  })
    .then((response) => response)
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const isLiborRateExpired = (cancelToken) => {
  return request({
    url: liborAPI.isLiborExpired,
    method: "GET",
    cancelToken: cancelToken,
  })
    .then((response) => response)
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const liborService = {
  getLiborRates,
  createLibor,
  getLiborRate,
  updateLiborRate,
};

export default liborService;
