import { ActionTypes } from "../ActionTypes";

const { LIBOR_RATE } = ActionTypes;

const INITIAL_STATE = {
  paginatedLiborRates: { data: [], count: 0 },
  isLiborExpired: false,
};

const LiborRateReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case LIBOR_RATE.SET_LIBOR_RATES_LIST:
      return {
        ...state,
        paginatedLiborRates: payload,
      };
    case LIBOR_RATE.SET_IS_LIBOR_EXPIRED:
      return {
        ...state,
        isLiborExpired: payload,
      };
    default:
      return state;
  }
};

export default LiborRateReducer;
