import {
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Col, Dropdown, Input, Layout, Menu, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { logoutUrl, removeTokens } from "../../lib/CognitoUtils";
import { selectCurrentUserPage } from "../../redux/common/common.selector";
import { selectCurrentUser } from "../../redux/user/user.selector";
import { setSearchText } from "../../redux/search/search.action";
import PropTypes from "prop-types";
import "./top-bar.style.css";
import { useHistory, useLocation } from "react-router-dom";
import { RouteNames } from "../../utils";
import { isEmpty } from "lodash";
import WebStorage from "../../lib/WebStorage";
import Media from "react-media";
import routesList from "../../routes/routes-list";
const { Header } = Layout;
const { Search } = Input;

const TopBar = ({ collapsed, collapsedToggle }) => {
  const currentUserPage = useSelector(selectCurrentUserPage, shallowEqual);
  const currentUser = useSelector(selectCurrentUser, shallowEqual);

  const dispatch = useDispatch();
  const location = useLocation();
  const [urlParam] = useState(location.search);
  const [search, setSearch] = useState();

  const history = useHistory();
  history.listen((location, action) => {
    if (!isEmpty(location.search)) {
      const searchParams = new URLSearchParams(location.search);
      const searchText = searchParams.get("s");
      if (searchText) {
        setSearch(searchText);
        dispatch(setSearchText(searchText));
      
      } else {
        setSearch("");
        dispatch(setSearchText(""));
      }
    } else {
      setSearch("");
      dispatch(setSearchText(""));
    }
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(urlParam);
    console.log("searchParams", searchParams);
    if (!isEmpty(urlParam)) {
      const searchParams = new URLSearchParams(urlParam);
      console.log("searchParams", searchParams);
      const searchText = searchParams.get("s");
      if (searchText) {
        setSearch(searchText);
        dispatch(setSearchText(searchText));
      }
    }
  }, [urlParam]);

  const onLogOut = () => {
    removeTokens();
    window.location.assign(logoutUrl);
  };

  const redirectToSearch = (searchText) => {
    console.log("redirectToSearch")
    window.scroll(0, 0)
    WebStorage.setLocalStore('scrollposkey', 0)
    history.push(`${RouteNames.GlobalSearch}?s=${searchText}`);
      setSearchText(searchText);
      dispatch(setSearchText(searchText));
  };

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <span>Account Setting</span>
      </Menu.Item>
      <Menu.Item key="1" onClick={onLogOut}>
        <span>Log Out</span>
      </Menu.Item>
    </Menu>
  );

  const DesktopSearchBar = ({ search, setSearch, redirectToSearch }) => {
    const inputRef = useRef(null);
    const [hasChanged, setHasChanged] = useState(false);

    useEffect(() => {
      if (inputRef.current && (search || hasChanged)) {
        inputRef.current.focus();
      }
    }, [search, hasChanged]);

    return (
      <Search
        ref={inputRef}
      placeholder="Search Artwork Title, Card Number, Artist, Tags"
      style={{ width: 380 }}
      allowClear
      value={search}
      onChange={(e) => {
        setSearch(e.target.value);
        if (!hasChanged) {
          setHasChanged(true);
        }
      }}
      onFocus={() => {
        if (!hasChanged) {
          setHasChanged(true);
        }
      }}
      onBlur={() => {
        if (!search) {
          setHasChanged(false);
        }
      }}
      onSearch={() => {
        redirectToSearch(search);
      }}
      enterButton
      />
    );
  };

  const MobileSearchBar = ({ search, setSearch, redirectToSearch }) => {
    const mobileInputRef = useRef(null);
    const [localValue, setLocalValue] = useState(search);

    return (
      <Search
        ref={mobileInputRef}
        placeholder="Search Artwork Title, Card Number, Artist, Tags"
        style={{ width: 380 }}
        allowClear
        value={localValue}
        onChange={(e) => setLocalValue(e.target.value)}
        onSearch={() => {
          redirectToSearch(mobileInputRef.current.input.value);
        }}
        enterButton
      />
    );
  };

  const ResponsiveSearchBar = ({search, setSearch, redirectToSearch}) => {
   
    return (
    <>
      <Media query={{ maxWidth: 599 }}> 
        {matches => !matches ?   
        <DesktopSearchBar search={search} setSearch={setSearch} redirectToSearch={redirectToSearch} /> : 
        window.location.href.includes('/artwork-list')? 
        <MobileSearchBar search={search} setSearch={setSearch} redirectToSearch={redirectToSearch} /> : <></>
        }
       
        </Media> 
       
</>
    );
  };

  const ResponsiveSearchBardropdown = () => {
    return (
      <>
       
      
       <Media query={{ maxWidth: 599 }}> 
              
         {matches => matches && 
         <Col  style={{ padding: "0 20px", }} xs={10}  sm={8} md={8} lg={8} xl={5} xxl={4}>
           
        <Row >
            <Dropdown overlay={menu} trigger={["click"]}>
              <a className="ant-dropdown-link">
                {currentUser ? (
                  <div className="name-email" style={{ padding: "12px" }}>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        lineHeight: "22px",
                      }}
                    >
                      {currentUser.name}
                    </span>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        lineHeight: "12px",
                      }}
                    >
                      {currentUser.email}
                    </span>
                  </div>
                ) : (
                  ""
                )}
                <DownOutlined
                  style={{
                    padding: 10,
                    position: "absolute",
                    right: "10px",
                    top: "17px",
                    fontSize: "12px",
                    color: " rgba(0,0,0,0.25)",
                  }}
                />
              </a>
            </Dropdown>
          </Row>
          </Col>
           }
       </Media> 
   
      </>
    );
  };

  
  return (
    window.location.href.includes('/artwork-list') ? 
    <Header className="site-layout-background artworklisting" style={{ padding: 0}}>
    <Row>
      <Col sm={2}xs={2}  md={2} lg={2} xl={1} xxl={1}>
        {collapsed ? (
          <MenuUnfoldOutlined
            className="trigger"
            onClick={() => collapsedToggle(!collapsed)}
          />
        ) : (
          <MenuFoldOutlined
            className="trigger"
            onClick={() => collapsedToggle(!collapsed)}
          />
        )}
      </Col>

      <Col xs={12} sm={6} md={6} lg={6} xl={10} xxl={10}>
        <span className="collection-span">{currentUserPage}</span>
      </Col>

         <ResponsiveSearchBardropdown/>
      <Col
        className="header-search align-right "
        xs={24}
        sm={24}
        md={8}
        lg={8}
        xl={8}
        xxl={9}
        style={{ padding: "5px 10px" }}
      >
          
        <ResponsiveSearchBar 
          search={search}
          setSearch={setSearch}
          redirectToSearch={redirectToSearch}
        />
      </Col>
      
      <Col  style={{ padding: "0 20px", }} xs={0}  sm={0} md={8} lg={8} xl={5} xxl={4}>
        <Row >
          <Dropdown overlay={menu} trigger={["click"]}>
            <a className="ant-dropdown-link">
              {currentUser ? (
                <div className="name-email" style={{ padding: "12px" }}>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      lineHeight: "22px",
                    }}
                  >
                    {currentUser.name}
                  </span>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      lineHeight: "12px",
                    }}
                  >
                    {currentUser.email}
                  </span>
                </div>
              ) : (
                ""
              )}
              <DownOutlined
                style={{
                  padding: 10,
                  position: "absolute",
                  right: "10px",
                  top: "17px",
                  fontSize: "12px",
                  color: " rgba(0,0,0,0.25)",
                }}
              />
            </a>
          </Dropdown>
        </Row>
      </Col>
    </Row>
  </Header> : <Header className="site-layout-background" style={{ padding: 0 }}>
  <Row>
    <Col sm={2}xs={2}  md={2} lg={2} xl={1} xxl={1}>
      {collapsed ? (
        <MenuUnfoldOutlined
          className="trigger"
          onClick={() => collapsedToggle(!collapsed)}
        />
      ) : (
        <MenuFoldOutlined
          className="trigger"
          onClick={() => collapsedToggle(!collapsed)}
        />
      )}
    </Col>

    <Col xs={12} sm={6} md={6} lg={6} xl={10} xxl={10}>
      <span className="collection-span">{currentUserPage}</span>
    </Col>

       <ResponsiveSearchBardropdown/>
    <Col
      className="header-search align-right "
      xs={24}
      sm={24}
      md={8}
      lg={8}
      xl={8}
      xxl={9}
      style={{ padding: "5px 10px" }}
    >
        
      <ResponsiveSearchBar 
        search={search}
        setSearch={setSearch}
        redirectToSearch={redirectToSearch}
      />
    </Col>
    
    <Col  style={{ padding: "0 20px", }} xs={0}  sm={0} md={8} lg={8} xl={5} xxl={4}>
      <Row >
        <Dropdown overlay={menu} trigger={["click"]}>
          <a className="ant-dropdown-link">
            {currentUser ? (
              <div className="name-email" style={{ padding: "12px" }}>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    lineHeight: "22px",
                  }}
                >
                  {currentUser.name}
                </span>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    lineHeight: "12px",
                  }}
                >
                  {currentUser.email}
                </span>
              </div>
            ) : (
              ""
            )}
            <DownOutlined
              style={{
                padding: 10,
                position: "absolute",
                right: "10px",
                top: "17px",
                fontSize: "12px",
                color: " rgba(0,0,0,0.25)",
              }}
            />
          </a>
        </Dropdown>
      </Row>
    </Col>
  </Row>
</Header>
    
  );
};

TopBar.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  collapsedToggle: PropTypes.func.isRequired,
};

export default TopBar;
