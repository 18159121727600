import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import ArtworkReducer from "./artwork/artwrok.reducers";
import CommonReducer from "./common/common.reducer";
import ContactReducer from "./contact/contact.reducer";
import UserReducer from "./user/user.reducer";
import LocationReducer from "./location/location.reducer";
import MovementReducer from "./movement/movement.reducer";
import GroupsReducer from "./groups/groups.reducer";
import InsurancePolicyReducer from "./insurance-policies/insurance-policy.reducer";
import LiborRateReducer from "./libor-rate/libor-rate.reducer";
import CountryIptRateReducer from "./country-ipt/country-ipt.reducer";
import createFilter from "redux-persist-transform-filter";
import { encryptTransform } from "redux-persist-transform-encrypt";
import MarketplaceReducer from "./marketplace/marketplace.reducer";
import SearchReducer from "./search/search.reducer";

const saveSubsetFilter = createFilter("userReducer", ["currentUser"]);

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["userReducer"],
  transforms: [
    saveSubsetFilter,
    encryptTransform({
      secretKey: "uPRd9gUo<36Y]wM",
      onError: function (error) {
        console.log("Persist Error : ", error);
      },
    }),
  ],
};

const AppReducers = combineReducers({
  commonReducer: CommonReducer,
  userReducer: UserReducer,
  artworkReducer: ArtworkReducer,
  contactReducer: ContactReducer,
  locationReducer: LocationReducer,
  movementReducer: MovementReducer,
  groupsReducer: GroupsReducer,
  insurancePolicyReducer: InsurancePolicyReducer,
  liborRateReducer: LiborRateReducer,
  marketplaceReducer: MarketplaceReducer,
  iptRateReducer: CountryIptRateReducer,
  searchReducer: SearchReducer,
});

export default persistReducer(persistConfig, AppReducers);
// export default (state, action) => AppReducers(state, action);
